import React, { useEffect, useState } from 'react'
import { deleteUser, signOut } from 'firebase/auth'
import './Profile.css'
import { Diamond, Home, Paid } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import Header from '../Header/Header'
import { auth, provider } from '../../firebase-config'
import { signInWithPopup } from 'firebase/auth'

import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

function Profile() {
    const navigate = useNavigate()
    const user_id = localStorage.getItem('user_id')
    const user_name = localStorage.getItem('user_name')
    const user_email = localStorage.getItem('user_email')
    const user_createdAt = localStorage.getItem('user_createdAt')
    const user_lastActiveAt = localStorage.getItem('user_lastActiveAt')
    const [apiCalled, setApiCalled] = useState(false)

    const [userName, setUserName] = useState('')
    const [diamondCount, setDiamondCount] = useState(0)

    const signUserOut = () => {
        signOut(auth).then(() => {
            localStorage.clear()
            window.location.pathname = '/login'
        })
    }

    const getUserInfo = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uid: user_id })
        }

        fetch('https://api.studencity.in/api/upsc/signin', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('User has opened his profile.')
                if (data.status === 200) {
                    setUserName(data.data.list.name)
                    setDiamondCount(data.data.list.coins)
                    setApiCalled(true)
                } else {
                    console.log('Error : ', data)
                    localStorage.clear()
                    window.location.pathname = '/login'
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const leaderboardHandler = () => {
        navigate('/leaderboard')
    }

    const rewardsHandler = () => {
        navigate('/rewards')
    }

    const clearDataOfUser = (uid) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uid })
        }

        fetch('https://api.studencity.in/api/upsc/user/cleardata', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setDiamondCount(0)
                if (data.status === 200) {
                } else {
                    console.log('Error : ', data)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const deleteAccountOfUser = (uid) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uid })
        }

        fetch('https://api.studencity.in/api/upsc/user', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    localStorage.clear()
                    navigate('/login')
                } else {
                    console.log('Error : ', data)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            if (localStorage.getItem('isAuth') !== 'userLoggedIn') {
                navigate('/login')
            } else {
                getUserInfo()
            }
        }
    }, [apiCalled])

    return (
        <>
            {userName.length > 0 && (
                <>
                    <div className='profile__container'>
                        <div className='user'>{userName} </div>
                        <div className='diamond'>
                            <div>Coins : {diamondCount}</div>
                        </div>
                        <div className='signout' onClick={signUserOut}>
                            <button>Sign Out</button>
                        </div>
                        <div className='account__settings'>
                            <button className='clear__data__button' onClick={() => clearDataOfUser(user_id)}>
                                Clear Data
                            </button>
                            <button className='delete__account__button' onClick={() => deleteAccountOfUser(user_id)}>
                                Delete Account
                            </button>
                        </div>
                    </div>
                    <div className='features__container'>
                        <div className='leaderboard' onClick={leaderboardHandler}>
                            <div>Leaderboard</div>
                        </div>
                        <div className='leaderboard' onClick={rewardsHandler}>
                            <div>Rewards</div>
                        </div>
                    </div>
                </>
            )}
            {userName.length === 0 && (
                <div className='loader__container'>
                    <div>Loading...</div>
                </div>
            )}
        </>
    )
}

export default Profile
