import './App.css'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import Home from './components/Home/Home'
import Quiz from './components/Quiz/Quiz'
import Profile from './components/Profile/Profile'
import Login from './components/Login/Login'
import Leaderboard from './components/Leaderboard/Leaderboard'
import Rewards from './components/Rewards/Rewards'
import PDFs from './components/PDFs/PDFs'
import DailyQuizSet from './components/DailyQuizSet/DailyQuizSet'
import QuizRoom from './components/QuizRoom/QuizRoom'
import Header from './components/Header/Header'

function App() {
    return (
        <Router>
            <Header/>
            <Routes>
                <Route path='/' element={<Quiz />} />
                <Route path='/home' element={<Home />} />
                <Route path='/login' element={<Login />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/leaderboard' element={<Leaderboard />} />
                <Route path='/rewards' element={<Rewards />} />
                <Route path='/pdfs' element={<PDFs />} />
                <Route path='/daily-quiz' element={<DailyQuizSet />} />
                <Route path='/quiz-room' element={<QuizRoom />} />
            </Routes>
        </Router>
    )
}

export default App
