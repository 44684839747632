import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: 'AIzaSyBhmIArGLyv-ghY_ZMhV8c6O67Rzu6PbwQ',
    authDomain: 'upsc-infact.firebaseapp.com',
    projectId: 'upsc-infact',
    storageBucket: 'upsc-infact.appspot.com',
    messagingSenderId: '897147572844',
    appId: '1:897147572844:web:59dc9ea4d39ff2775a3e49',
    measurementId: 'G-TQ20MW8GL5'
}

const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)
export const auth = getAuth(app)
export const provider = new GoogleAuthProvider()
