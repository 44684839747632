import React, { useEffect } from 'react'
import Header from '../Header/Header'
import './PDFs.css'

import pdfIcon from '../../assets/pdficon.png'
import { useNavigate } from 'react-router-dom'

function PDFs() {

    const navigate = useNavigate()
    
    const downloadPDFHandler = () => {
        window.open('https://common-icons.s3.ap-south-1.amazonaws.com/others/pdf/August-2023.pdf')
    }

    useEffect(() => {
        if (localStorage.getItem('isAuth') !== 'userLoggedIn') {
            navigate('/login')
        }
    })
    return (
        <>
            <div className='pdf__main__container'>
                <h1>Monthly PDFs</h1>
                <div className='pdf__container' onClick={() => downloadPDFHandler()}>
                    <div className='pdf__name'>
                        August, 2023
                    </div>
                    <div className='pdf__icon'>
                        <img src={pdfIcon} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PDFs