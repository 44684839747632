import React from 'react'
import './Header.css'
import { useNavigate } from 'react-router-dom'

function Header() {

  const navigate = useNavigate()
  const routeHandler = (route) => {
    navigate('/' + route)
  }

  return (
    <div className='header__container'>
      <div className='cursor-pointer' onClick={() => routeHandler('')}>UPSC inFact</div>
      <div className='cursor-pointer' onClick={() => routeHandler('login')}>Profile</div>
    </div>
  )
}

export default Header