import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import './Leaderboard.css'
import { Paid } from '@mui/icons-material'
import coinIcon from '../../assets/coin4.png'

function capitalizeWords(name) {
    const capitalizedResult = name
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    return capitalizedResult
}

function Leaderboard() {

    const [users, setUsers] = useState([])
    const [apiCalled, setApiCalled] = useState(false)
    const uid = localStorage.getItem("user_id")
    const getLeaderboardData = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch('https://api.studencity.in/api/upsc/leaderboard', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Leaderboard has been fetched.')
                if (data.status === 200) {
                    setUsers(data.data.list)
                    setApiCalled(true)
                } else {
                    console.log('Error : ', data)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getLeaderboardData()
        }
    }, [apiCalled])

    return (
        <>
            <div className='leaderboard__container'>
                <div className='header'>Leaderboard</div>
                {
                    users.length === 0 && <div>Loading...</div>
                }
                <div className='users'>
                    {
                        users.length > 0 && users.map((user, i) => {
                            if (user.uid === uid) {
                                return (
                                    <div className='each_user current__user cp' key={i}>
                                        <div>
                                            {i + 1 + '. ' + capitalizeWords(user.name)}
                                        </div>
                                        <div className='diamond'>
                                            {user.coins}
                                            <img src={coinIcon} alt="coin" />
                                            {/* <Paid sx={{ fontSize: "25px", marginLeft: "5px", marginTop: "0.1vw", color: "rgb(84, 134, 243)" }} /> */}
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className='each_user cp' key={i}>
                                        <div>
                                            {i + 1 + '. ' + capitalizeWords(user.name)}
                                        </div>
                                        <div className='diamond'>
                                            {user.coins}
                                            <img src={coinIcon} alt="coin" />
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Leaderboard