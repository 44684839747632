import React, { useEffect, useState } from 'react'
import './Quiz.css'
import { RestartAlt } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Button, CardActionArea } from '@mui/material'
import { basicQuestions } from '../../data/basicQuestions'

function Quiz() {
    const navigate = useNavigate()
    const { width, height } = useWindowSize()

    const [question, setQuestion] = useState('')
    const [options, setOptions] = useState([])
    const [answerId, setAnswerId] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [explanation, setExplanation] = useState('')
    const [chosenOption, setChosenOption] = useState('')
    const [showConfetti, setShowConfetti] = useState(false)
    const [topic, setTopic] = useState('')
    const [subtopic, setSubtopic] = useState('')
    const [apiCalled, setApiCalled] = useState(false)
    const [showSavedQuestion, setShowSavedQuestion] = useState(true)

    const getRewardForCorrectQuizAnswer = () => {
        const userID = localStorage.getItem('user_id')
        if (userID === null || userID.length < 2) {
            return console.log('Rewards will not be granted to trial user.')
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uid: localStorage.getItem('user_id') })
        }

        fetch('https://api.studencity.in/api/upsc/quiz/rewards', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const submitAnswerHandler = () => {
        setSubmitted(true)
        if (chosenOption === answerId) {
            getRewardForCorrectQuizAnswer()
            setShowConfetti(true)
            setTimeout(() => {
                setShowConfetti(false)
            }, 3000)
        } else {
        }
    }

    const handleChosenOption = (id) => {
        setChosenOption(id)
    }

    const newQuestionHandler = () => {
        setShowConfetti(false)
        setQuestion('')
        setTopic('')
        setSubtopic('')
        setOptions([])
        console.log('From new question handler...')
        freeUserTrail()
        setSubmitted(false)
        setChosenOption('')
    }

    const getRandomQuizQuestion = () => {
        if (showSavedQuestion) {
            console.log('Showing saved questions...')
            const randomQuestionIndex = Math.floor(Math.random() * 200)
            const firstQuestion = basicQuestions[randomQuestionIndex]
            setQuestion(firstQuestion.question)
            setOptions(firstQuestion.options)
            setAnswerId(firstQuestion.answerId)
            setExplanation(firstQuestion.explanation || '')
            setTopic(firstQuestion.topic)
            setSubtopic(firstQuestion.subtopic)
            setApiCalled(true)
            setShowSavedQuestion(false)
            return
        }

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch('https://api.studencity.in/api/upsc/quiz/random', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setQuestion(data.data.list[0].question)
                    setOptions(data.data.list[0].options)
                    setAnswerId(data.data.list[0].answerId)
                    setExplanation(data.data.list[0].explanation || '')
                    setTopic(data.data.list[0].topic)
                    setSubtopic(data.data.list[0].subtopic)
                    setApiCalled(true)
                } else {
                    console.log('Error : ', data)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const freeUserTrail = () => {
        if (localStorage.getItem('user_id') !== null) {
            getRandomQuizQuestion()
            return
        }

        if (localStorage.getItem('free_user_trial_attempts') === null) {
            localStorage.setItem('free_user_trial_attempts', '0')
        }
        let currentValue = parseInt(localStorage.getItem('free_user_trial_attempts'))

        if (currentValue > 5) {
            navigate('/login')
            return
        } else {
            getRandomQuizQuestion()
        }

        currentValue++
        localStorage.setItem('free_user_trial_attempts', currentValue.toString())
    }

    useEffect(() => {
        document.title = 'UPSC inFact'
        if (!apiCalled) {
            if (localStorage.getItem('isAuth') !== 'userLoggedIn') {
                freeUserTrail()
            } else {
                getRandomQuizQuestion()
            }
        }
    }, [apiCalled])

    const handleRoute = (url) => {
        navigate(url)
    }

    return (
        <>
            <div className='h-[calc(100vh_-_6vh)] lg:flex bg-gray-300'>
                <div className='h-[calc(100vh_-_6vh)] lg:w-1/6 bg-gray-800 text-white px-4'>
                    <div className='my-4'>
                        <ul>
                            <li className='py-2 cursor-pointer hover:text-blue-400' onClick={() => handleRoute('quiz-room')}>
                                Live Quiz Room
                            </li>
                        </ul>
                    </div>
                    <div className='mb-4'>
                        <ul>
                            <li className='py-2'>Geography</li>
                            <li className='border-t border-gray-700 py-2'>History</li>
                            <li className='border-t border-gray-700 py-2'>Economy</li>
                            <li className='border-t border-gray-700 py-2'>Polity</li>
                            <li className='border-t border-gray-700 py-2'>National</li>
                            <li className='border-t border-gray-700 py-2'>International</li>
                            <li className='border-t border-gray-700 py-2'>Sports</li>
                            <li className='border-t border-gray-700 py-2'>Defence</li>
                            <li className='border-t border-gray-700 py-2'>GK</li>
                            <li className='border-t border-gray-700 py-2'>Science</li>
                        </ul>
                    </div>
                    <div className='mb-4'>
                        <ul>
                            <li className='py-2'>January, 2024</li>
                            <li className='border-t border-gray-700 py-2'>December, 2023</li>
                            <li className='border-t border-gray-700 py-2'>November, 2023</li>
                            <li className='border-t border-gray-700 py-2'>October, 2023</li>
                            <li className='border-t border-gray-700 py-2'>September, 2023</li>
                        </ul>
                    </div>
                </div>

                {/* Main Content */}
                <div className='h-[calc(100vh_-_6vh)] lg:w-5/6 px-4'>
                    <div className='main__outer__container'>
                        <div className='quiz__container'>
                            {/* <h1>GK Quiz</h1> */}
                            {question.length === 0 && (
                                <div className='loading__container'>
                                    <p>Loading...</p>
                                </div>
                            )}
                            <div className='topic__container mt-2'>{topic.length > 0 && <span className='bg-green-300 topic__text pt'> {topic + ' - ' + subtopic} </span>} </div>
                            <div className='question__container'>{question.length > 0 && question}</div>
                            <div className='answer__container'>
                                {options.length > 0 &&
                                    options.map((option) => {
                                        if (submitted) {
                                            if (option.id === answerId) {
                                                return <div className='correctAnswer'>{option.value}</div>
                                            } else if (option.id === chosenOption) {
                                                return <div className='wrongAnswer'>{option.value}</div>
                                            } else {
                                                return <div className='eachAnswer'>{option.value}</div>
                                            }
                                        } else {
                                            if (option.id === chosenOption) {
                                                return <div className='chosenOption'>{option.value}</div>
                                            } else {
                                                return (
                                                    <div className='eachAnswer' onClick={() => handleChosenOption(option.id)}>
                                                        {option.value}
                                                    </div>
                                                )
                                            }
                                        }
                                    })}
                            </div>
                            {!submitted && question.length > 0 && (
                                <>
                                    <div className='submit__container'>
                                        <div className='submitBtn' onClick={submitAnswerHandler}>
                                            Submit
                                        </div>
                                    </div>
                                </>
                            )}
                            {submitted && (
                                <>
                                    {explanation.length === 0 && <div className='explanation__container'>Explanation: No explanation</div>}
                                    {explanation.length > 0 && <div className='explanation__container'>Explanation: {explanation}</div>}
                                    <div className='restart__container'>
                                        <RestartAlt className='restartIcon' sx={{ fontSize: '40px' }} onClick={newQuestionHandler} />
                                    </div>
                                </>
                            )}
                        </div>
                        <div class='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 mx-4 mt-4'>
                            <div>
                                <Card sx={{ backgroundColor: '#333', color: '#fff' }}>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant='h5' component='div'>
                                                General Knowledge Quiz
                                            </Typography>
                                            <Button size='small' variant='contained' color='primary'>
                                                Attempt
                                            </Button>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                            <div>
                                <Card sx={{ backgroundColor: '#333', color: '#fff' }}>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant='h5' component='div'>
                                                General Facts
                                            </Typography>
                                            <Button size='small' variant='contained' color='primary'>
                                                View
                                            </Button>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                        </div>

                        {showConfetti && <Confetti width={width} height={height} numberOfPieces={100} tweenDuration={10} initialVelocityY={10} />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Quiz
