exports.basicQuestions = [
    {
        _id: {
            $oid: '64c110cb6586fcb4aceda2f2'
        },
        question: 'Which agency assigns Geographical Tag to goods?',
        options: [
            {
                id: 'pk101',
                value: 'Geographical Indication Board Limited'
            },
            {
                id: 'ld542',
                value: 'Geographical Commission of India'
            },
            {
                id: 're432',
                value: 'Geographical Indication Registry'
            },
            {
                id: 'md234',
                value: 'NITI Aayog'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690374347466'
        },
        __v: 0,
        explanation: 'GI Tag is given to the goods by the Geographical Indication Registry under the Geographical Indications of Goods Registration and Protection Act, 1999. The Registry is headquartered in Chennai.',
        uploadedAt: {
            $numberLong: '1696958231511'
        },
        subtopic: 'Geographical Indication',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c110e26586fcb4aceda2f6'
        },
        question: 'Who was the third person who won Bharat Ratna in 1992 along with Abul Kalam Azad and Satyajit Ray?',
        options: [
            {
                id: 'pk101',
                value: 'Morarji Desai'
            },
            {
                id: 'ld542',
                value: 'Gulzarilal Nanda'
            },
            {
                id: 're432',
                value: 'JRD Tata'
            },
            {
                id: 'md234',
                value: 'Rajiv Gandhi'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690374370263'
        },
        __v: 0,
        explanation: "Abul Kalam Azad was India's first Minister of Education and worked towards free primary education. In 1992, he got Bharat Ratna along with Satyajit Ray, and JRD Tata.",
        uploadedAt: {
            $numberLong: '1696958233000'
        },
        subtopic: 'Awards and Honours',
        topic: 'GK'
    },
    {
        _id: {
            $oid: '64c110f36586fcb4aceda2fa'
        },
        question: 'In which year, the Anti-defection law was added to Constitution by 52nd Amendment Act?',
        options: [
            {
                id: 'pk101',
                value: '1985'
            },
            {
                id: 'ld542',
                value: '1986'
            },
            {
                id: 're432',
                value: '1988'
            },
            {
                id: 'md234',
                value: '1983'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690374387850'
        },
        __v: 0,
        explanation: 'The anti-defection law in India was added to the Constitution in 1985 by the 52nd Amendment Act. It is also the 10th Schedule of the Indian Constitution.',
        uploadedAt: {
            $numberLong: '1697046058315'
        },
        subtopic: 'Acts and Policies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64c1110d6586fcb4aceda2fe'
        },
        question: 'Which continent is famous for majority of Lithium reserves?',
        options: [
            {
                id: 'pk101',
                value: 'South America'
            },
            {
                id: 'ld542',
                value: 'Asia'
            },
            {
                id: 're432',
                value: 'Australia'
            },
            {
                id: 'md234',
                value: 'Europe.'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690374413900'
        },
        __v: 0,
        explanation: "The intersection of Chile, Bolivia and Northwest Argentina constitutes the region known as Lithium Triangle. Lithium is also known as 'White Gold'. 66% of world's Lithium reserves are found in South America.",
        uploadedAt: {
            $numberLong: '1697046309445'
        },
        subtopic: 'Minerals',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c1112b6586fcb4aceda302'
        },
        question: 'In which state, the Mhadei Wildlife Sanctuary is located?',
        options: [
            {
                id: 'pk101',
                value: 'Karnataka'
            },
            {
                id: 'ld542',
                value: 'Tamil Nadu'
            },
            {
                id: 're432',
                value: 'Maharashtra'
            },
            {
                id: 'md234',
                value: 'Goa'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690374443294'
        },
        __v: 0,
        explanation: 'Tiger Reserves are notified by State Governments as per provisions of the Wildlife Protection Act, 1972 on advice of the National Tiger Conservation Authority. Mhadei Wildlife Sanctuary is located in northern part of Goa. Vazra falls is located near Mhadei sanctuary.',
        uploadedAt: {
            $numberLong: '1697253372830'
        },
        subtopic: 'National Parks and Wildlife Sanctuaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c111406586fcb4aceda306'
        },
        question: 'Which peak is highest peak of Goa?',
        options: [
            {
                id: 'pk101',
                value: 'Sonsogod'
            },
            {
                id: 'ld542',
                value: 'Agastya Malai'
            },
            {
                id: 're432',
                value: 'Anjaneri'
            },
            {
                id: 'md234',
                value: 'Pushpagiri'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690374464062'
        },
        __v: 0,
        explanation: 'Sonsogod is the highest peak of Goa having the elevation of 1027 metres. It is located in Mhadei wildlife sanctuary. Mhadei River, a lifeline of Goa, originates in Karnataka, passes through the sanctuary, and meets the Arabian Sea at Panaji.',
        uploadedAt: {
            $numberLong: '1697346887372'
        },
        subtopic: 'Mountains and Peaks',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c111586586fcb4aceda30a'
        },
        question: 'Who introduced Income Tax is India in 1860?',
        options: [
            {
                id: 'pk101',
                value: 'James Wilson'
            },
            {
                id: 'ld542',
                value: 'Lord Canning'
            },
            {
                id: 're432',
                value: 'Karl Marx'
            },
            {
                id: 'md234',
                value: 'Lord Hardinge'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690374488122'
        },
        __v: 0,
        explanation: 'Income Tax Day is observed on 24th July every year. On 24th July 1860 Sir James Wilson introduced income tax for the first time in India.Wilson arrived in India in 1859 to help the British government review its expenditures after the Sepoy Mutiny.',
        uploadedAt: {
            $numberLong: '1697346888900'
        },
        subtopic: 'Taxation in India',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64c111716586fcb4aceda30e'
        },
        question: 'What is the name of highest peak of Argentina?',
        options: [
            {
                id: 'pk101',
                value: 'Mount Aconcagua'
            },
            {
                id: 'ld542',
                value: 'Mount Ojos Salado'
            },
            {
                id: 're432',
                value: 'Mount Pissis'
            },
            {
                id: 'md234',
                value: 'Mount Huascaran'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690374513310'
        },
        __v: 0,
        explanation: 'Mount Aconcagua is the highest peak of Argentina. India is 4th largest trading partner of Argentina. Argentina is known for Pampas grasslands. Parana and Pilcomayo rivers are found in Argentina.',
        uploadedAt: {
            $numberLong: '1697446735173'
        },
        subtopic: 'Mountains and Peaks',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c111886586fcb4aceda312'
        },
        question: ' For how many years, Geographical tag is valid?',
        options: [
            {
                id: 'pk101',
                value: '10 years'
            },
            {
                id: 'ld542',
                value: ' 20 years'
            },
            {
                id: 're432',
                value: ' 5 years'
            },
            {
                id: 'md234',
                value: ' 30 years'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690374536631'
        },
        __v: 0,
        explanation: 'The first product that was awarded the GI tag in India was Darjeeling Tea in 2004. The GI Tag is valid for 10 years and it can be renewed for next 10 years. Karnataka has the highest number of GI tags.',
        uploadedAt: {
            $numberLong: '1697346884203'
        },
        subtopic: 'Geographical Indication',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c40b6ef4452eefede53440'
        },
        question: 'Which Bhakti saint poet propounded the philosophy of Advaita, also known as Monoism?',
        options: [
            {
                id: 'pk101',
                value: 'Madhavacharya'
            },
            {
                id: 'ld542',
                value: 'Sankaracharya'
            },
            {
                id: 're432',
                value: 'Ramanuja'
            },
            {
                id: 'md234',
                value: 'Nimbraka'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690569582039'
        },
        __v: 0,
        explanation: 'Sankaracharya was mystic Bhakti poet-saint leader of 8th century. He propounded the philosophy of Advaita, also known as Monoism. He wrote books like Vivek Chudamani and Upadesh Shashtri.',
        uploadedAt: {
            $numberLong: '1697253635490'
        },
        subtopic: 'Religious Movements',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c40b77f4452eefede53444'
        },
        question: 'Which Bhakti saint poet of Sriperumbudur founded Shrivaishnava sect?',
        options: [
            {
                id: 'pk101',
                value: 'Ramanuja'
            },
            {
                id: 'ld542',
                value: 'Sankaracharya'
            },
            {
                id: 're432',
                value: 'Madhavacharya'
            },
            {
                id: 'md234',
                value: 'Nimbraka'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690569591397'
        },
        __v: 0,
        explanation: 'Ramanuja was a Bhakti saint poet who was born at Sriperumbudur near Chennai. He founded the Shrivaishnava sect and advocated the philosophy of Vishista Advaitavada, also known as Qualified Monoism.',
        uploadedAt: {
            $numberLong: '1697446737176'
        },
        subtopic: 'Religious Movements',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c40b7df4452eefede53448'
        },
        question: 'Which Bhakti saint poet authored Gita Bhasya and Vedantsara?',
        options: [
            {
                id: 'pk101',
                value: 'Sankaracharya'
            },
            {
                id: 'ld542',
                value: 'Nimbraka'
            },
            {
                id: 're432',
                value: 'Madhavacharya'
            },
            {
                id: 'md234',
                value: 'Ramanuja'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690569597952'
        },
        __v: 0,
        explanation: 'Gita Bhasya and Vedantasara were written by Ramanuja, a Bhakti saint poet. He was born in 1017 at Sriperumbudur near Chennai. He was firm supporter of Prabattimarga or the path of self-surrender to God.',
        uploadedAt: {
            $numberLong: '1697907470003'
        },
        subtopic: 'Religious Movements',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c40b85f4452eefede5344c'
        },
        question: 'Which Bhakti saint poet propagated Dvaita or the dualism of Jivatma and Paramatma?',
        options: [
            {
                id: 'pk101',
                value: 'Ramanuja'
            },
            {
                id: 'ld542',
                value: 'Sankaracharya'
            },
            {
                id: 're432',
                value: 'Madhavacharya'
            },
            {
                id: 'md234',
                value: 'Nimbraka'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690569605565'
        },
        __v: 0,
        explanation: 'Brahma Sampradya was founded by Madhavacharya, born in 1238. He propagated Dvaita or the dualism of Jivatma and Paramatma.',
        uploadedAt: {
            $numberLong: '1701345281675'
        },
        subtopic: 'Religious Movements',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c40b8cf4452eefede53450'
        },
        question: 'Which Bhakti saint poet authored Bhaja Govindum Stotra?',
        options: [
            {
                id: 'pk101',
                value: 'Sankaracharya'
            },
            {
                id: 'ld542',
                value: 'Madhavacharya'
            },
            {
                id: 're432',
                value: 'Nimbraka'
            },
            {
                id: 'md234',
                value: 'Ramanuja '
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690569612230'
        },
        __v: 0,
        explanation: 'Bhaja Govindum Stotra was written by Sankaracharya, a Bhakti saint poet in 8th century. Sankaracharya also wrote commentary on Bhagvat Gita and Brahmasutra.',
        uploadedAt: {
            $numberLong: '1701345397728'
        },
        subtopic: 'Religious Movements',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c40b96f4452eefede53454'
        },
        question: 'Which is the largest producer of coffee in India?',
        options: [
            {
                id: 'pk101',
                value: 'Nagaland'
            },
            {
                id: 'ld542',
                value: 'Tamil Nadu'
            },
            {
                id: 're432',
                value: 'Assam'
            },
            {
                id: 'md234',
                value: 'Karnataka'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690569622308'
        },
        __v: 0,
        explanation: 'Karnataka is the largest producer of coffee in India. It accounts for around 70% of total coffee production. Mysore state was renamed Karnataka in 1973.',
        uploadedAt: {
            $numberLong: '1697447269224'
        },
        subtopic: 'Agriculture Sector',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c40ba3f4452eefede53458'
        },
        question: 'What is name of river which is tributary of Gomti river and also called Aadi Ganga?',
        options: [
            {
                id: 'pk101',
                value: 'Mailani river'
            },
            {
                id: 'ld542',
                value: 'Sai river'
            },
            {
                id: 're432',
                value: 'Sukheta river'
            },
            {
                id: 'md234',
                value: 'Assi river'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690569635803'
        },
        __v: 0,
        explanation: 'Gomti river is tributary of Ganga river and it originates from Gomti Taal in Uttar Pradesh. The Sai river, also known as Aadi Ganga, is tributary of Gomti river.',
        uploadedAt: {
            $numberLong: '1701345399078'
        },
        subtopic: 'Rivers and Climate',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c40bb8f4452eefede5345c'
        },
        question: 'Which river comprises of 40.2% of the entire drainage system of Ganges Basin?',
        options: [
            {
                id: 'pk101',
                value: 'Gomti'
            },
            {
                id: 'ld542',
                value: 'Brahmaputra'
            },
            {
                id: 're432',
                value: 'Kosi'
            },
            {
                id: 'md234',
                value: 'Yamuna'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690569656886'
        },
        __v: 0,
        explanation: 'Hindon river is tributary of Yamuna river. It originates from the Yamunotri Glacier in the Himalayas and has a drainage system of 40.2% of the entire Ganges Basin.',
        uploadedAt: {
            $numberLong: '1697907740810'
        },
        subtopic: 'Rivers and Climate',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c40be0f4452eefede53460'
        },
        question: 'When did Bangalore become electrified by Shivanasamundra Hydroelectric plant?',
        options: [
            {
                id: 'pk101',
                value: '1908'
            },
            {
                id: 'ld542',
                value: '1906'
            },
            {
                id: 're432',
                value: '1915'
            },
            {
                id: 'md234',
                value: '1902'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690569696109'
        },
        __v: 0,
        explanation: 'Bangalore become the 41st city to become part of World Cities Culture Forum, WCCF which is a network of cities for exploring role of culture in future prosperity. In 1906, Bangalore became first city to be electrified powered by Shivanasamundra Hydroelectric plant.',
        uploadedAt: {
            $numberLong: '1701345518696'
        },
        subtopic: 'Mineral and Energy Resources',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c40bf2f4452eefede53464'
        },
        question: 'In which year, Central Reserve Police Force was established on 27th July?',
        options: [
            {
                id: 'pk101',
                value: '1945'
            },
            {
                id: 'ld542',
                value: '1939'
            },
            {
                id: 're432',
                value: '1954'
            },
            {
                id: 'md234',
                value: '1962'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690569714078'
        },
        __v: 0,
        explanation: 'The Central Reserve Police Force Raising Day is observed on 27th July every year. The headquarters of CRPF are in New Delhi. It was established on 27th July, 1939.',
        uploadedAt: {
            $numberLong: '1701435191213'
        },
        subtopic: 'National Security',
        topic: 'Defence'
    },
    {
        _id: {
            $oid: '64c40bfdf4452eefede53468'
        },
        question: 'Which ruler built Hauz-i-Shamsi reservoir in Mehrauli in 1230?',
        options: [
            {
                id: 'pk101',
                value: 'Iltutmish'
            },
            {
                id: 'ld542',
                value: 'Qutubddin Aibak'
            },
            {
                id: 're432',
                value: 'Razia Sultan'
            },
            {
                id: 'md234',
                value: 'Balban'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690569725920'
        },
        __v: 0,
        explanation: 'Iltutmish built the Hauz-i-Shamsi reservoir in Mehrauli in 1230. He was the first ruler to issue regular currency and declare Delhi as capital of his empire.',
        uploadedAt: {
            $numberLong: '1697534773491'
        },
        subtopic: 'The Mughal Empire',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c49ee5f4452eefede534f7'
        },
        question: 'Which king of Chalukyan kingdom is mentioned in Aihole Inscription by his court poet Ravikirti?',
        options: [
            {
                id: 'pk101',
                value: 'Vikramaditya I'
            },
            {
                id: 'ld542',
                value: 'Kirtivarman II'
            },
            {
                id: 're432',
                value: 'Mangalesha'
            },
            {
                id: 'md234',
                value: 'Pulakeshin II'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690607333705'
        },
        __v: 0,
        explanation: 'Pulakeshin II was most powerful king of Badami Chalukyas in 7th century. His court poet was Ravikirti. His eulogy is written in the Aihole Inscription.',
        uploadedAt: {
            $numberLong: '1697534770605'
        },
        subtopic: 'Early Medieval Period',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c49f07f4452eefede534fd'
        },
        question: 'Which king of Chalukyan Empire was contemporary of Harsh Vardhana and defeated him on banks of river Narmada?',
        options: [
            {
                id: 'pk101',
                value: 'Mangalesha'
            },
            {
                id: 'ld542',
                value: 'Kirtivarman II'
            },
            {
                id: 're432',
                value: 'Pulakeshin II'
            },
            {
                id: 'md234',
                value: 'Vikramaditya I'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690607367557'
        },
        __v: 0,
        explanation: 'Pulakeshin II was contemporary of Harsh Vardhana and defeated him on the banks of river Narmada. He assumed the title of Dakshinpatheshvara, also known as Lord of South.',
        uploadedAt: {
            $numberLong: '1701435192123'
        },
        subtopic: 'Early Medieval Period',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c49f26f4452eefede53503'
        },
        question: 'Who was the last ruler of Badami Chalukyas and died in 757 C.E?',
        options: [
            {
                id: 'pk101',
                value: 'Kirtivarman II'
            },
            {
                id: 'ld542',
                value: 'Pulakeshin II'
            },
            {
                id: 're432',
                value: 'Mangalesha'
            },
            {
                id: 'md234',
                value: 'Vikramaditya I'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690607398992'
        },
        __v: 0,
        explanation: 'Kirtivarman II was the last ruler of Badami Chalukyas. He was defeated by the Rashtrakuta king Dantidurga. Thus Chalukyan rule came to end in 757 C.E.',
        uploadedAt: {
            $numberLong: '1701687564922'
        },
        subtopic: 'Early Medieval Period',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c49f3bf4452eefede53509'
        },
        question: ' Which ruler took the title of Rajamalla during 7th century after defeating Pallavas?',
        options: [
            {
                id: 'pk101',
                value: 'Kirtivarman II'
            },
            {
                id: 'ld542',
                value: 'Pulakeshin II'
            },
            {
                id: 're432',
                value: 'Mangalesha'
            },
            {
                id: 'md234',
                value: 'Vikramaditya I'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690607419843'
        },
        __v: 0,
        explanation: 'Vikramaditya I of Chalukyan kingdom took the title of Rajamalla, also known as Sovereign of the Malls. He pushed the Pallavas out of Badami when Chulakyan kingdom was captured after the death of Pulakeshin II in 642 CE.',
        subtopic: 'Early Medieval Period',
        topic: 'History',
        uploadedAt: {
            $numberLong: '1701971430763'
        }
    },
    {
        _id: {
            $oid: '64c49f51f4452eefede5350f'
        },
        question: 'Which Buddhist monk visited the Chalukyan kingdom during the tenure of Pulakeshin II in 7th century?',
        options: [
            {
                id: 'pk101',
                value: 'Hsuan Hua'
            },
            {
                id: 'ld542',
                value: 'Fa Hian'
            },
            {
                id: 're432',
                value: 'Itsing'
            },
            {
                id: 'md234',
                value: 'Hsuan Tsang'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690607441721'
        },
        __v: 0,
        explanation: 'Hsuan Tsang visited Chalukyan kingdom during the tenure of Pulakeshin II. After the death of Pulakeshin II in 642 C.E, the Badami was occupied by Pallavas for period of 13 years.',
        uploadedAt: {
            $numberLong: '1701687566338'
        },
        subtopic: 'Early Medieval Period',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c4a71ef4452eefede5351f'
        },
        question: "Which lake became Ladakh's first Biodiversity Heritage Site under Biology Diversity Act?",
        options: [
            {
                id: 'pk101',
                value: 'Tsangpo Lake'
            },
            {
                id: 'ld542',
                value: 'Yaya Tso Lake'
            },
            {
                id: 're432',
                value: 'Tso Kar Lake'
            },
            {
                id: 'md234',
                value: 'Pangong Tso Lake'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690609438488'
        },
        __v: 0,
        explanation: "Yaya Tso Lake has been declared Ladakh's first Biodiversity Heritage Site under Biology Diversity Act. Secure Himalaya Project was started in 2017 for conservation of Himalayn Ecosystem.",
        subtopic: 'States and UTs',
        topic: 'Geography',
        uploadedAt: {
            $numberLong: '1701971432408'
        }
    },
    {
        _id: {
            $oid: '64c4a72ef4452eefede53525'
        },
        question: 'Which port is largest port of India by cargo handled?',
        options: [
            {
                id: 'pk101',
                value: 'Mundra Port'
            },
            {
                id: 'ld542',
                value: 'Kolkata Port'
            },
            {
                id: 're432',
                value: 'Paradip Port'
            },
            {
                id: 'md234',
                value: 'Jawaharlal Nehru Port'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690609454485'
        },
        __v: 0,
        explanation: "Gujarat has the longest coastline in India covering around 1600 kms. It handle around 40% of India's ocean cargo. The Mundra port is also the largest port of India by cargo handled.",
        subtopic: 'Industrial Sector',
        topic: 'Economy',
        uploadedAt: {
            $numberLong: '1702052692387'
        }
    },
    {
        _id: {
            $oid: '64c4a73af4452eefede53529'
        },
        question: 'For which category, the Miles Franklin Award is given annually?',
        options: [
            {
                id: 'pk101',
                value: 'Science'
            },
            {
                id: 'ld542',
                value: 'Literature'
            },
            {
                id: 're432',
                value: 'Sports'
            },
            {
                id: 'md234',
                value: 'Peace'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690609466409'
        },
        __v: 0,
        explanation: 'The Miles Franklin Literary Award is annual literary prize which presents Australian life. Shankari Chandran won 2023 award for Chai time at Cinnamon Gardens.',
        subtopic: 'Awards and Honours',
        topic: 'GK',
        uploadedAt: {
            $numberLong: '1702536030996'
        }
    },
    {
        _id: {
            $oid: '64c4a746f4452eefede5352d'
        },
        question: 'Which river valley houses the largest coal fields of Jharia in Jharkhand?',
        options: [
            {
                id: 'pk101',
                value: 'Brahmaputra River Valley'
            },
            {
                id: 'ld542',
                value: 'Ganga River Valley'
            },
            {
                id: 're432',
                value: 'Damodar River Valley'
            },
            {
                id: 'md234',
                value: 'Kosi River Valley'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690609478345'
        },
        __v: 0,
        explanation: 'Jharia is located in Jharkhand. It is famous for large coal fields. The coal fields are located in Damodar River Valley. It produces bituminous coal suitable for coke.',
        subtopic: 'Minerals',
        topic: 'Geography',
        uploadedAt: {
            $numberLong: '1702536032784'
        }
    },
    {
        _id: {
            $oid: '64c4a752f4452eefede53531'
        },
        question: 'In which year, Kigali Amendment was added to Montreal Protocol for phasing down hydroflourocarbons?',
        options: [
            {
                id: 'pk101',
                value: '2021'
            },
            {
                id: 'ld542',
                value: '1985'
            },
            {
                id: 're432',
                value: '1987'
            },
            {
                id: 'md234',
                value: '2016'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690609490375'
        },
        __v: 0,
        explanation: 'Vienna Convention 1985 and Montreal Protocol 1987 are initiatives for protection of ozole layer. In 2016, Kigali Amendment was added to Montreal Protocol for phasing down Hydroflourocarbons.',
        subtopic: 'Treaties and Conventions',
        topic: 'International',
        uploadedAt: {
            $numberLong: '1704028062458'
        }
    },
    {
        _id: {
            $oid: '64c4a765f4452eefede53535'
        },
        question: 'Which unit is used for measuring the amount of ozone concentration?',
        options: [
            {
                id: 'pk101',
                value: 'Glider'
            },
            {
                id: 'ld542',
                value: 'Gamow'
            },
            {
                id: 're432',
                value: 'Dobson'
            },
            {
                id: 'md234',
                value: 'Columbus'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690609509459'
        },
        __v: 0,
        explanation: "The Dobson unit is a unit of measurement for the amount of a trace gas in a vertical column through the Earth's atmosphere. It is named after Gordon Dobson who built the first instrument to measure total ozone from the ground.",
        subtopic: 'Climate Change',
        topic: 'Geography',
        uploadedAt: {
            $numberLong: '1704028064621'
        }
    },
    {
        _id: {
            $oid: '64c4a76cf4452eefede53539'
        },
        question: 'On which day, the World Ozone day is observed?',
        options: [
            {
                id: 'pk101',
                value: '16 July'
            },
            {
                id: 'ld542',
                value: '16 September'
            },
            {
                id: 're432',
                value: '31 July'
            },
            {
                id: 'md234',
                value: '21 April'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690609516916'
        },
        __v: 0,
        explanation: 'September 16 is observed as World Ozone Day. Gordon Dobson built the first instrument to measure the ozone concentration in air from the ground. Montreal Protocol for Ozone protection was signed in 1987.',
        subtopic: 'Climate Change',
        topic: 'Geography',
        uploadedAt: {
            $numberLong: '1704524351002'
        }
    },
    {
        _id: {
            $oid: '64c51ad6f4452eefede53556'
        },
        question: 'Which European explorer conquered Mexico in 1519 after overthrowing Aztec empire?',
        options: [
            {
                id: 'pk101',
                value: 'Inge Lehmann'
            },
            {
                id: 'ld542',
                value: 'Leonardo da Vinci'
            },
            {
                id: 're432',
                value: 'Hernan Cortes'
            },
            {
                id: 'md234',
                value: 'Gaspar Da Gama'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690639062317'
        },
        __v: 0,
        explanation: 'Hernan Cortes was European explorer who conquered Mexico. He overthrew Aztec empire in 1519 and won Mexico for the crown of Spain. He also helped colonize Cuba and started the phase of Spanish colonization of the Americas.',
        subtopic: 'World History',
        topic: 'History',
        uploadedAt: {
            $numberLong: '1704524687787'
        }
    },
    {
        _id: {
            $oid: '64c51aecf4452eefede5355c'
        },
        question: 'Who made The Last Supper mural painting in 1495?',
        options: [
            {
                id: 'pk101',
                value: 'Gaspar da Gama'
            },
            {
                id: 'ld542',
                value: 'Hernan Cortes'
            },
            {
                id: 're432',
                value: 'Inge Lehmann'
            },
            {
                id: 'md234',
                value: 'Leonardo da Vinci'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690639084383'
        },
        __v: 0,
        explanation: 'The Last Supper is mural painting made by Leonardo da Vinci around 1495. It depicts the scene of the Last Supper of Jesus with the Twelve Apostles. It was made for the Dominican monastery Santa Maria in Milan, Italy.',
        subtopic: 'Art and Culture',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c51b07f4452eefede53560'
        },
        question: 'Which country hosted the UN COP 27 Climate Change conference?',
        options: [
            {
                id: 'pk101',
                value: 'France'
            },
            {
                id: 'ld542',
                value: 'Saudi Arabia'
            },
            {
                id: 're432',
                value: 'India'
            },
            {
                id: 'md234',
                value: 'Egypt'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690639111238'
        },
        __v: 0,
        explanation: 'The UN-COP 27 Summit was held in Egypt in 2022. It was related to Climate Change and it established legally binding obligations for developed countries to reduce their greenhouse gas emissions.',
        subtopic: 'Climate Change',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c51b14f4452eefede53566'
        },
        question: 'Which discontinuity is found between the inner core and outer core of earth?',
        options: [
            {
                id: 'pk101',
                value: 'Mohorovicic'
            },
            {
                id: 'ld542',
                value: 'Conrad'
            },
            {
                id: 're432',
                value: 'Lehmann'
            },
            {
                id: 'md234',
                value: 'Repiti'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690639124706'
        },
        __v: 0,
        explanation: 'Lehmann discontinuity is found in the innermost part of earth. It is found between Inner core and Outer core. It was discovered by seismologist Inge Lehmann.',
        subtopic: 'The Earth and Universe',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c51b2df4452eefede5356a'
        },
        question: 'Where is the Counter Insurgency and Jungle Warfare School of Indian Army located?',
        options: [
            {
                id: 'pk101',
                value: 'Arunachal Pradesh'
            },
            {
                id: 'ld542',
                value: 'Assam'
            },
            {
                id: 're432',
                value: 'Jammu and Kashmir'
            },
            {
                id: 'md234',
                value: 'Mizoram'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690639149578'
        },
        __v: 0,
        explanation: 'The Counter-Insurgency and Jungle Warfare School is located in Mizoram. It is established by Indian Army for specialized unconventional warfare. It was established in 1967.',
        subtopic: 'Indian Army',
        topic: 'Defence'
    },
    {
        _id: {
            $oid: '64c51b38f4452eefede5356e'
        },
        question: 'Who served as Viceroy of India and Governor General between 1936 and 1944?',
        options: [
            {
                id: 'pk101',
                value: 'Lord Wellesley'
            },
            {
                id: 'ld542',
                value: 'Lord Hardinge'
            },
            {
                id: 're432',
                value: 'Lord Cornwallis'
            },
            {
                id: 'md234',
                value: 'Lord Linlithgow'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690639160617'
        },
        __v: 0,
        explanation: 'Lord Linlithgow served as Governor General and Viceroy of India from 1936 to 1944. He participated in all three Round Table Conferences in 1930, 1932 and 1933. Bengal famine of 1943 happened during his tenure.',
        subtopic: 'Viceroy and Governor-Generals',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c51b44f4452eefede53572'
        },
        question: 'Who was the Governor General of India when Cripps Mission and Bengal Famine occured?',
        options: [
            {
                id: 'pk101',
                value: 'Lord Wellesley'
            },
            {
                id: 'ld542',
                value: 'Lord Hardinge'
            },
            {
                id: 're432',
                value: 'Lord Cornwallis'
            },
            {
                id: 'md234',
                value: 'Lord Linlithgow'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690639172910'
        },
        __v: 0,
        explanation: 'Cripps Mission of 1942 and Bengal Famine of 1943 events happened during Lord Linlithgow tenure from 1936 to 1944 as Governor General and Viceroy of India. His eight years period was longest reign as Viceroy of India.',
        subtopic: 'Viceroy and Governor-Generals',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c53c75f4452eefede535c6'
        },
        question: 'For how many years, Shivaji ruled Maratha kingdom?',
        options: [
            {
                id: 'pk101',
                value: '8 years'
            },
            {
                id: 'ld542',
                value: '6 years'
            },
            {
                id: 're432',
                value: '10 years'
            },
            {
                id: 'md234',
                value: '12 years'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690647669188'
        },
        __v: 0,
        explanation: 'Shivaji Raje Bhonsle ruled Maratha kingdom for six years from 1674 to 1680. When his guardian Dadaji Kondadev died in 1647, Shivaji assumed full charge of Poona jagir.',
        subtopic: 'The Maratha Kingdom',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c53c90f4452eefede535ca'
        },
        question: 'In which year the Battle of Pratapgarh was fought where Shivaji killed Afzal Khan with his tiger claws?',
        options: [
            {
                id: 'pk101',
                value: '1659'
            },
            {
                id: 'ld542',
                value: '1660'
            },
            {
                id: 're432',
                value: '1658'
            },
            {
                id: 'md234',
                value: '1656'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690647696670'
        },
        __v: 0,
        explanation: 'The battle of Pratapgarh 1659 was fought between Shivaji and Afzal Khan. Shivaji killed Afzal Khan with his tiger claws and became local hero. Shivaji was born in the hill fortress of Shivmer in Poona in 1630.',
        subtopic: 'The Maratha Kingdom',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c53c99f4452eefede535ce'
        },
        question: 'Which language was promoted by Shivaji during his tenure from 1674 to 1680?',
        options: [
            {
                id: 'pk101',
                value: 'Prakrit'
            },
            {
                id: 'ld542',
                value: 'Hindi'
            },
            {
                id: 're432',
                value: 'Marathi'
            },
            {
                id: 'md234',
                value: 'Sanskrit'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690647705656'
        },
        __v: 0,
        explanation: 'Shivaji assumed the title of Maharaja Chattrapati in 1672 when he defeated Mughals in Battle of Salher. During his tenure, Shivaji opposed forced conversions and promoted Sanskrit language.',
        subtopic: 'The Maratha Kingdom',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c53cb5f4452eefede535d2'
        },
        question: 'Under which dynasty, Shahji Bhonsle was military commander?',
        options: [
            {
                id: 'pk101',
                value: 'Nizam Shahi Dynasty'
            },
            {
                id: 'ld542',
                value: 'Daulatabad Dynasty'
            },
            {
                id: 're432',
                value: 'Berar Dynasty'
            },
            {
                id: 'md234',
                value: 'Golconda Dynasty'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690647733256'
        },
        __v: 0,
        explanation: "Shahji Bhonsle, father of Shivaji, was a military commander under the Nizam Shahi ruler of Ahmednagar. Shivaji was named Shiva after the local goddess 'Shivai'. Shivaji ruled for only 6 years from 1674 to 1680.",
        subtopic: 'The Maratha Kingdom',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c53cbbf4452eefede535d6'
        },
        question: 'Which ruler of Maratha kingdom was also called Kalusha (instigator of strife)?',
        options: [
            {
                id: 'pk101',
                value: 'Rajaram'
            },
            {
                id: 'ld542',
                value: 'Shivaji'
            },
            {
                id: 're432',
                value: 'Sambhaji'
            },
            {
                id: 'md234',
                value: 'Shahji'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690647739146'
        },
        __v: 0,
        explanation: 'Sambhaji was the successor of Shivaji when he dies in 1680 due to fever. Sambhaji was nicknamed Kalusha which means the Instigator of strife. The internal conflict with his brother Rajaram weakened Maratha power.',
        subtopic: 'The Maratha Kingdom',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64c730a22f4fc05afe14ee5b'
        },
        question: 'Which climate type of Koeppen is found in Ganga plain and eastern Rajasthan?',
        options: [
            {
                id: 'pk101',
                value: 'As'
            },
            {
                id: 'ld542',
                value: 'Amw'
            },
            {
                id: 're432',
                value: 'Cwg'
            },
            {
                id: 'md234',
                value: 'Aw'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690775714665'
        },
        __v: 0,
        explanation: "The climate type 'Cwg' of Koeppen classification represents Monsoon type with dry winters. It includes Ganga plain and eastern Rajasthan. Koeppen’s scheme is based on the monthly values of temperature and precipitation.",
        subtopic: 'Rivers and Climate',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c730ad2f4fc05afe14ee5f'
        },
        question: 'Which layer of atmosphere has the greatest amount of water vapours?',
        options: [
            {
                id: 'pk101',
                value: 'Ionosphere'
            },
            {
                id: 'ld542',
                value: 'Troposphere'
            },
            {
                id: 're432',
                value: 'Mesosphere'
            },
            {
                id: 'md234',
                value: 'Stratosphere'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690775725981'
        },
        __v: 0,
        explanation: 'The Troposphere contains about 75% of all of the air in the atmosphere, and almost all of the water vapour which forms clouds and rain.',
        subtopic: 'Earth Atmosphere',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c730eb2f4fc05afe14ee63'
        },
        question: 'In which state, the Nokrek Biosphere Reserve is located?',
        options: [
            {
                id: 'pk101',
                value: 'Assam'
            },
            {
                id: 'ld542',
                value: 'Arunachal Pradesh'
            },
            {
                id: 're432',
                value: 'Sikkim'
            },
            {
                id: 'md234',
                value: 'Meghalaya'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690775787937'
        },
        __v: 0,
        explanation: 'The Nokrek Biosphere Reserve is located on the Tura Range in Meghalaya. It was added by UNESCO to Biosphere Reserves in May 2009. Nokrek is the highest peak of Garo range.',
        subtopic: 'National Parks and Wildlife Sanctuaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c731002f4fc05afe14ee67'
        },
        question: 'Which mission was started in December 2014 to expand immunization coverage to all children across India?',
        options: [
            {
                id: 'pk101',
                value: 'Mission Indradhanush'
            },
            {
                id: 'ld542',
                value: 'Swachh Bharat Mission'
            },
            {
                id: 're432',
                value: 'Beti Bachao Beti Padhao'
            },
            {
                id: 'md234',
                value: 'Kusum Mission'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690775808539'
        },
        __v: 0,
        explanation: 'Mission Rainbow or Mission Indradhanush was started in December, 2014. The aim of mission is to expand immunization coverage to all children across India.',
        subtopic: 'Government Schemes',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64c731182f4fc05afe14ee6b'
        },
        question: "In which state, the Byculla railway station, India's oldest railway station is located?",
        options: [
            {
                id: 'pk101',
                value: 'Maharashtra'
            },
            {
                id: 'ld542',
                value: 'West Bengal'
            },
            {
                id: 're432',
                value: 'Gujarat'
            },
            {
                id: 'md234',
                value: 'Goa'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690775832578'
        },
        __v: 0,
        explanation: 'Byculla railway station is India’s oldest railway station. it is located in central Mumbai with 169 year old history. The station has been honoured with the prestigious Asia Pacific Cultural Heritage award by UNESCO.',
        subtopic: 'Transportation Sector of India',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c731232f4fc05afe14ee6f'
        },
        question: 'In which range of Himalayas, the Parkachik Glacier is located?',
        options: [
            {
                id: 'pk101',
                value: 'Karakoram Range'
            },
            {
                id: 'ld542',
                value: 'Zanskar Range'
            },
            {
                id: 're432',
                value: 'Ladakh Range'
            },
            {
                id: 'md234',
                value: 'Hindukush Range'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690775843914'
        },
        __v: 0,
        explanation: 'Parkachik Glacier is located in Zanskar Himalayas. It is inside Suru sub-basin of Upper Indus river.The Zanskar River is the first major tributary of the Indus River, equal or greater in volume than the main river.',
        subtopic: 'Physiography and Locations',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c731352f4fc05afe14ee73'
        },
        question: 'Which UNESCO World Heritage site is also known as Sahyadri mountain range?',
        options: [
            {
                id: 'pk101',
                value: 'Karakoram Range'
            },
            {
                id: 'ld542',
                value: 'Eastern Ghats'
            },
            {
                id: 're432',
                value: 'Zanskar Range'
            },
            {
                id: 'md234',
                value: 'Western Ghats'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690775861699'
        },
        __v: 0,
        explanation: 'The Western Ghats are also known as the Sahyadri mountain ranges. It is a UNESCO World Heritage site.The Western Ghats are older than the Himalayas. It starts near Tapti river and runs approximately 1600 kms.',
        subtopic: 'Heritage Sites and UNESCO',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c731492f4fc05afe14ee77'
        },
        question: 'Which is the highest peak of Maharashtra?',
        options: [
            {
                id: 'pk101',
                value: 'Kalsubai'
            },
            {
                id: 'ld542',
                value: 'Salher'
            },
            {
                id: 're432',
                value: 'Gawaldev'
            },
            {
                id: 'md234',
                value: 'Anamudi'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690775881271'
        },
        __v: 0,
        explanation: 'Kalsubai is the highest peak in Maharashtra located in Ahmednagar district. Kaas Plateau also known as Maharashtra valley of flowers is situated in Sahyadri range of Western Ghats.',
        subtopic: 'Mountains and Peaks',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c7315f2f4fc05afe14ee7b'
        },
        question: 'In which state, the Dampa Tiger Reserve is located?',
        options: [
            {
                id: 'pk101',
                value: 'Gujarat'
            },
            {
                id: 'ld542',
                value: 'Assam'
            },
            {
                id: 're432',
                value: 'Mizoram'
            },
            {
                id: 'md234',
                value: 'Madhya Pradesh'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690775903797'
        },
        __v: 0,
        explanation: 'Dampa Tiger Reserve is located in Lushai Hills of Mizoram. It was declared a Tiger Reserve in 1994 under Project Tiger. Project Tiger was started on 1st April, 1973 under Wildlife Protection Act, 1972.',
        subtopic: 'National Parks and Wildlife Sanctuaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c87f182f4fc05afe14eedb'
        },
        question: "Which type of currency is also called 'Programmable Money' as it can be programmed for certain selected users or within specific region of country?",
        options: [
            {
                id: 'pk101',
                value: 'Bitcoins'
            },
            {
                id: 'ld542',
                value: 'Cryptocurrency'
            },
            {
                id: 're432',
                value: 'Masala Bonds'
            },
            {
                id: 'md234',
                value: 'Central Bank Digital Currency'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690861336146'
        },
        __v: 0,
        explanation: "The Central Bank Digital Currency is digital currency backed by Central Bank of country. It is also called Digital Fiat Currency. It is also called as 'Programmable Money' as it can be programmed for certain selected users or within specific region of country.",
        subtopic: 'Indian Fiscal System',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64c87f352f4fc05afe14eee1'
        },
        question: 'Which organization , headquartered in Belgium, aims to develop a secure electronic service and common standard to facilitate cross-border payments?',
        options: [
            {
                id: 'pk101',
                value: 'WTO'
            },
            {
                id: 'ld542',
                value: 'UN'
            },
            {
                id: 're432',
                value: 'SWIFT'
            },
            {
                id: 'md234',
                value: 'WEF'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690861365711'
        },
        __v: 0,
        explanation: 'The Society for Worldwide Interbank Financial Telecommunication, SWIFT was founded on 3 May, 1973 by 15 countries. It is headquartered in Belgium. Their aim is to develop a secure electronic service and common standards to facilitate cross-border payments.',
        subtopic: 'Payment System',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64c87f4d2f4fc05afe14eee5'
        },
        question: 'Which institution introduced Voluntary Retention Route (VRR) to enable Foreign Portfolio Investors to invest in debt markets in India?',
        options: [
            {
                id: 'pk101',
                value: 'EXIM'
            },
            {
                id: 'ld542',
                value: 'SEBI'
            },
            {
                id: 're432',
                value: 'IDBI'
            },
            {
                id: 'md234',
                value: 'RBI'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690861389703'
        },
        __v: 0,
        explanation: 'The Voluntary Retention Route (VRR) is a channel introduced by the Reserve Bank of India in March, 2019 to enable Foreign Portfolio Investors to invest in debt markets in India. The investment limit is Rs 2.5 lakh crore.',
        subtopic: 'Investments',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64c87f602f4fc05afe14eeeb'
        },
        question: 'Which institution launched e-RUPI, a cashless voucher which can be redeemed multiple times?',
        options: [
            {
                id: 'pk101',
                value: 'NPCI'
            },
            {
                id: 'ld542',
                value: 'RBI'
            },
            {
                id: 're432',
                value: 'IDBI'
            },
            {
                id: 'md234',
                value: 'ECGC'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690861408278'
        },
        __v: 0,
        explanation: 'The e-RUPI is a prepaid digital voucher developed by National Payments Corporation of India. It is a person-specific and purpose-specific cashless voucher and it can be redeemed multiple times.',
        subtopic: 'Payment System',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64c87f6e2f4fc05afe14eeef'
        },
        question: 'Which index is used to measure the progress of country towards SDG-2 (End Hunger, achieve food security and improved nutrition)?',
        options: [
            {
                id: 'pk101',
                value: 'Food and Agriculture Index'
            },
            {
                id: 'ld542',
                value: 'World Agriculture Report'
            },
            {
                id: 're432',
                value: 'Agriculture Supply and Demand Report'
            },
            {
                id: 'md234',
                value: 'Agriculture Orientation Index'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690861422557'
        },
        __v: 0,
        explanation: "The Agriculture Orientation Index is published by the Food and Agriculture Organization. It is used to measure the progress of country towards SDG-2 (End Hunger, achieve food security and improved nutrition). India's AOI score is less than other Asian countries.",
        subtopic: 'United Nations',
        topic: 'International'
    },
    {
        _id: {
            $oid: '64c87f7c2f4fc05afe14eef3'
        },
        question: 'Which authority is cadre controlling authority of the Indian Economic Services(IES) ?',
        options: [
            {
                id: 'pk101',
                value: 'Finance Ministry'
            },
            {
                id: 'ld542',
                value: 'Prime Minister Office'
            },
            {
                id: 're432',
                value: 'NITI Aayog'
            },
            {
                id: 'md234',
                value: 'Department of Economic Affairs'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690861436352'
        },
        __v: 0,
        explanation: 'The Chief Economic Adviser heads the Economic Division of the Department of Economic Affairs in the Ministry of Finance. It is the cadre controlling authority of the Indian Economic Service (IES).',
        subtopic: 'Indian Fiscal System',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64c87f872f4fc05afe14eef7'
        },
        question: 'What is the most important role of Chief Economic Adviser of India?',
        options: [
            {
                id: 'pk101',
                value: 'Prepare Economic Survey'
            },
            {
                id: 'ld542',
                value: 'Advice to States'
            },
            {
                id: 're432',
                value: 'Advice to NITI Aayog'
            },
            {
                id: 'md234',
                value: 'Prepare Union Budget'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690861447649'
        },
        __v: 0,
        explanation: 'The Chief Economic Adviser is equivalent to the rank of Secretary to the Government of India. He advises government on important economic policies. The most important role is to prepare the Economic Survey, which is presented one day before the Union Budget.',
        subtopic: 'Planning',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64c87f932f4fc05afe14eefb'
        },
        question: 'In which year, Manmohan Singh became Chief Economic Adviser to the Government of India?',
        options: [
            {
                id: 'pk101',
                value: '1972'
            },
            {
                id: 'ld542',
                value: '1982'
            },
            {
                id: 're432',
                value: '1992'
            },
            {
                id: 'md234',
                value: '1998'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690861459108'
        },
        __v: 0,
        explanation: 'Manmohan Singh is the only Prime Minister who was Chief Economic Adviser to the government from 1972 to 1976. The CEA is appointed by the Appointments Committee of the Cabinet.',
        subtopic: 'Planning',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64c94382c0207ba96ec4ed0b'
        },
        question: 'Which Particularly Vulnerable Tribal Group of Odisha live in Niyamgiri hill range?',
        options: [
            {
                id: 'pk101',
                value: 'Gadaba'
            },
            {
                id: 'ld542',
                value: 'Bonda'
            },
            {
                id: 're432',
                value: 'Dongria Kondh'
            },
            {
                id: 'md234',
                value: 'Saora'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690911618264'
        },
        __v: 0,
        explanation: 'Dongria Kondh are a Particularly Vulnerable Tribal Group from Odisha. They reside in Niyamgiri hill range and they worship Niyam Raja, the god of Niyamgiri Forests.',
        subtopic: 'Tribal Areas',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c9438cc0207ba96ec4ed0f'
        },
        question: 'Which mineral is found in Niyamgiri hills in Odisha where Dongria tribes are found?',
        options: [
            {
                id: 'pk101',
                value: 'Bauxite'
            },
            {
                id: 'ld542',
                value: 'Alumina'
            },
            {
                id: 're432',
                value: 'Aluminium'
            },
            {
                id: 'md234',
                value: 'Lithium'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690911628159'
        },
        __v: 0,
        explanation: 'Niyamgiri hills are famous for Bauxite. Dongria Kondh tribe reside in Niyamgiri hills. Odisha has largest tribal group in India with 62 tribes. Kui is their main language.',
        subtopic: 'Tribal Areas',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c943bfc0207ba96ec4ed13'
        },
        question: 'Which scheme has been started to make Indian cities and municipal areas begging-free?',
        options: [
            {
                id: 'pk101',
                value: 'KUSUM-25'
            },
            {
                id: 'ld542',
                value: 'SMILE-75'
            },
            {
                id: 're432',
                value: 'PENCIL-50'
            },
            {
                id: 'md234',
                value: 'NOBEG-100'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690911679164'
        },
        __v: 0,
        explanation: 'According to Census 2011, India has over 4 lakh beggars. The SMILE- 75 objective is to make Indian cities and municipal areas begging-free and make a strategy for rehabilitation of the persons engaged begging.',
        subtopic: 'Acts and Policies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64c943dcc0207ba96ec4ed17'
        },
        question: 'Which article of Indian Constitution prohibits the employment of children below 14 years of age in any hazardous industry?',
        options: [
            {
                id: 'pk101',
                value: 'Article 28'
            },
            {
                id: 'ld542',
                value: 'Article 22'
            },
            {
                id: 're432',
                value: 'Article 26'
            },
            {
                id: 'md234',
                value: 'Article 24'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690911708028'
        },
        __v: 0,
        explanation: "Article 24 of the Indian Constitution prohibits the employment of children below 14 years of age in any hazardous industry or factory or mine, without exception. However, it didn't prohibit their employment in any harmless or innocent work.",
        subtopic: 'The Constitution of India',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64c94404c0207ba96ec4ed1b'
        },
        question: 'In which year, the National Policy on Child Labour was formulated for rehabilitation of children working in hazardous conditions?',
        options: [
            {
                id: 'pk101',
                value: '1987'
            },
            {
                id: 'ld542',
                value: '1988'
            },
            {
                id: 're432',
                value: '1989'
            },
            {
                id: 'md234',
                value: '1986'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690911748163'
        },
        __v: 0,
        explanation: 'PENCIL Portal is an electronic platform that aims at achieving the target of child labour free society. The National Policy on Child Labour was formulated in 1987 with a focus on rehabilitation of children working in hazardous occupations.',
        subtopic: 'Acts and Policies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64c94468c0207ba96ec4ed1f'
        },
        question: 'In which direction, the Jet streams which are narrow bands of strong wind, blows?',
        options: [
            {
                id: 'pk101',
                value: 'North to South'
            },
            {
                id: 'ld542',
                value: 'East to West'
            },
            {
                id: 're432',
                value: 'West to East'
            },
            {
                id: 'md234',
                value: 'South to North'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690911848361'
        },
        __v: 0,
        explanation: 'Jet streams are narrow bands of strong wind in the upper levels of the atmosphere. The winds blow from west to east in jet streams.',
        subtopic: 'Earth Atmosphere',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64c94472c0207ba96ec4ed23'
        },
        question: 'Which organization was founded in 1992 as commercial and marketing arm of ISRO?',
        options: [
            {
                id: 'pk101',
                value: 'DRDO'
            },
            {
                id: 'ld542',
                value: 'Antrix Corporation'
            },
            {
                id: 're432',
                value: 'NNRMS'
            },
            {
                id: 'md234',
                value: 'Intelsat'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690911858523'
        },
        __v: 0,
        explanation: 'National Natural Resources Management System (NNRMS) is an integrated natural resource management system of India which aggregates the data about natural resources. Antrix Corporation was founded in 1992 as commercial and marketing arm of ISRO.',
        subtopic: 'Space Research',
        topic: 'Science'
    },
    {
        _id: {
            $oid: '64c94484c0207ba96ec4ed27'
        },
        question: 'Which site has been chosen for proposed Particle Physics Research Mega Project for studying neutrinos?',
        options: [
            {
                id: 'pk101',
                value: 'Nallamala Hills'
            },
            {
                id: 'ld542',
                value: 'Garo Hills'
            },
            {
                id: 're432',
                value: 'Palani Hills'
            },
            {
                id: 'md234',
                value: 'Bodi West Hills'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690911876510'
        },
        __v: 0,
        explanation: 'The Indian Neutrino Observatory is a proposed Particle Physics Research Mega Project to be set up in Bodi West Hills, Tamil Nadu. The 1200 metres deep cave is ideal site for studying neutrinos.',
        subtopic: 'Inventions and Discoveries',
        topic: 'Science'
    },
    {
        _id: {
            $oid: '64c94495c0207ba96ec4ed2b'
        },
        question: 'On which day, CV discovered the Raman Effect which went on to become National Science Day?',
        options: [
            {
                id: 'pk101',
                value: 'February 28'
            },
            {
                id: 'ld542',
                value: 'August 18'
            },
            {
                id: 're432',
                value: 'December 10'
            },
            {
                id: 'md234',
                value: 'March 21'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690911893628'
        },
        __v: 0,
        explanation: 'CV Raman became first person from Asia to be awarded a Nobel Prize in any field of science in 1930. He was awarded for his study on scattering of light and the discovery of Raman Effect. February 28 is celebrated as the National Science Day in India.',
        subtopic: 'Famous Scientists',
        topic: 'Science'
    },
    {
        _id: {
            $oid: '64c944b9c0207ba96ec4ed2f'
        },
        question: 'In which field, the Tokamak is used?',
        options: [
            {
                id: 'pk101',
                value: 'Nuclear Fusion'
            },
            {
                id: 'ld542',
                value: 'Cryptocurrency'
            },
            {
                id: 're432',
                value: 'Magnetic Induction'
            },
            {
                id: 'md234',
                value: 'Launch Vehicles'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690911929425'
        },
        __v: 0,
        explanation: 'Tokamak is a device used in nuclear-fusion research for magnetic confinement of plasma. It confines the plasma in a hollow, doughnut-shaped container.The tokamak concept was first developed in the 1950s by two Soviet physicists, Igor Tamm and Andrei Sakharov.',
        subtopic: 'Inventions and Discoveries',
        topic: 'Science'
    },
    {
        _id: {
            $oid: '64ca863bc0207ba96ec4ede4'
        },
        question: 'Who was the Viceroy and Governor-General of India from 1926 to 1931?',
        options: [
            {
                id: 'pk101',
                value: 'Lord Irwin'
            },
            {
                id: 'ld542',
                value: 'Lord Hardinge'
            },
            {
                id: 're432',
                value: 'Lord Linlitgow'
            },
            {
                id: 'md234',
                value: 'Lord Hastings'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690994235592'
        },
        __v: 0,
        explanation: 'The main aspects of Gandhi-Irwin Pact of 1931 were suspension of Civil Disobedience movement and release of political prisoners. It was signed on 5th August before Second Round Table Conference.Lord Irwin was Viceroy and Governor-General of India from 1926 to 1931.',
        subtopic: 'Viceroy and Governor-Generals',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64ca8678c0207ba96ec4ede8'
        },
        question: 'Which act provides social security plan offered by the Indian government to working class during maternity leave, mental or physical disorders?',
        options: [
            {
                id: 'pk101',
                value: 'The Employees State Security Act, 1948'
            },
            {
                id: 'ld542',
                value: 'The Employees State Insurance Act, 1956'
            },
            {
                id: 're432',
                value: 'The Employees State Insurance Act, 1948'
            },
            {
                id: 'md234',
                value: 'Employees State Security Act, 1958'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690994296097'
        },
        __v: 0,
        explanation: 'The Employees State Insurance Act, 1948 (ESI) is a social security plan offered by the Indian government that provides financial support and assistance to the working class during maternity leave, mental or physical disorders and disability.',
        subtopic: 'Acts and Policies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ca8688c0207ba96ec4edec'
        },
        question: 'In which year, All India Radio, national public radio broadcaster of India, was established?',
        options: [
            {
                id: 'pk101',
                value: '1936'
            },
            {
                id: 'ld542',
                value: '1946'
            },
            {
                id: 're432',
                value: '1956'
            },
            {
                id: 'md234',
                value: '1966'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690994312361'
        },
        __v: 0,
        explanation: 'All India Radio (AIR) is the national public radio broadcaster of India. It is governed by Prasar Bharti Board. It was established on 8th June, 1936.',
        subtopic: 'Communication Sector',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64ca8699c0207ba96ec4edf0'
        },
        question: 'In which year, the Millennium Development Goals were succeeded by Sustainable Development Goals?',
        options: [
            {
                id: 'pk101',
                value: '2012'
            },
            {
                id: 'ld542',
                value: '2000'
            },
            {
                id: 're432',
                value: '2008'
            },
            {
                id: 'md234',
                value: '2016'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690994329006'
        },
        __v: 0,
        explanation: 'The Millennium Development Goals (MDGs) were eight international development goals established in 2000 in Millennium Summit of United Nations. The Sustainable Development Goals (SDGs) succeeded the MDGs in 2016.',
        subtopic: 'Sustainable Development',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64ca86a4c0207ba96ec4edf4'
        },
        question: 'Which megaproject was started in 2021 to provide integrated and seamless connectivity for movement of people, goods, and services from one mode of transport to another?',
        options: [
            {
                id: 'pk101',
                value: 'PM Vani Shakti'
            },
            {
                id: 'ld542',
                value: 'PM Gati Shakti'
            },
            {
                id: 're432',
                value: 'PM MEITY'
            },
            {
                id: 'md234',
                value: 'PM Gravity'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690994340211'
        },
        __v: 0,
        explanation: 'PM Gati Shakti, also known as the National Master Plan for Multi-modal Connectivity, is an Indian megaproject worth 1.2 trillion US dollars. It aims to provide integrated and seamless connectivity for movement of people, goods, and services from one mode of transport to another. The plan was started in October 2021.',
        subtopic: 'Transportation Sector of India',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64ca86b8c0207ba96ec4edf8'
        },
        question: 'Who was appointed as the first Archaeological Surveyor of India in 1861?',
        options: [
            {
                id: 'pk101',
                value: 'Alexander Cunningham'
            },
            {
                id: 'ld542',
                value: 'CL Carlleyle'
            },
            {
                id: 're432',
                value: 'James Burgess'
            },
            {
                id: 'md234',
                value: 'John Marshall'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690994360702'
        },
        __v: 0,
        explanation: 'Kushinagar came into prominence in the 19th century with archaeological excavations carried out by Alexander Cunningham, the first Archaeological Surveyor of India and later followed by C.L. Carlleyle who exposed the main stupa and also discovered a 6.10 meters long statue of reclining Buddha in 1876. Buddha passed away at Kushinagar, which is known as Mahaparinirvana.',
        subtopic: 'Archaeology',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64ca86e5c0207ba96ec4edfc'
        },
        question: 'Which hydroxide is present in antacid tablets or medicines which are used to cure indigestion and acidity in stomach?',
        options: [
            {
                id: 'pk101',
                value: 'Potassium Hydroxide'
            },
            {
                id: 'ld542',
                value: 'Sodium Hydroxide'
            },
            {
                id: 're432',
                value: 'Calcium Hydroxide'
            },
            {
                id: 'md234',
                value: 'Aluminium Hydroxide'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690994405533'
        },
        __v: 0,
        explanation: 'Aluminum hydroxide is present in antacid tablets or medicines which are used to cure indigestion and acidity in stomach. Aluminium hydroxide is a mineral found in nature as gibbsite. The other rarer mineral types are bayerite, doyleite, and nordstrandite. It is amphoteric, meaning it has both basic and acidic properties.',
        subtopic: 'Biology',
        topic: 'Science'
    },
    {
        _id: {
            $oid: '64ca86f3c0207ba96ec4ee00'
        },
        question: 'On which day, the International Day for the Conservation of the Mangroves Ecosystem is observed since 2015 by UNESCO?',
        options: [
            {
                id: 'pk101',
                value: ' 26 June'
            },
            {
                id: 'ld542',
                value: '26 July'
            },
            {
                id: 're432',
                value: ' 24 August'
            },
            {
                id: 'md234',
                value: '24 September'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690994419020'
        },
        __v: 0,
        explanation: "The International Day for the Conservation of the Mangrove Ecosystem is celebrated every year on 26 July. It was adopted by the General Conference of the UN Educational, Scientific and Cultural Organization (UNESCO) in 2015. Mangrove cover in India is 0.15% of the country's total geographical area.",
        subtopic: 'Days and Events',
        topic: 'GK'
    },
    {
        _id: {
            $oid: '64ca872fc0207ba96ec4ee04'
        },
        question: 'Which part of India has a combination of mangrove forest, evergreen forest and deciduous forests?',
        options: [
            {
                id: 'pk101',
                value: 'Kerala'
            },
            {
                id: 'ld542',
                value: 'West Bengal'
            },
            {
                id: 're432',
                value: 'Andaman and Nicobar'
            },
            {
                id: 'md234',
                value: 'Tamil Nadu'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690994479615'
        },
        __v: 0,
        explanation: 'Andaman and Nicobar has has a combination of mangrove forest, evergreen forest and deciduous forest. It comprise 572 islands with 0.25 percent of the total geographical area of India. There are 200 plants in islands which are endemic(found nowhere else in the world).',
        subtopic: 'Natural Vegetation',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64ca873cc0207ba96ec4ee08'
        },
        question: 'In which year, the Telecom Regulatory Authority of India was established under TRAI Act?',
        options: [
            {
                id: 'pk101',
                value: '1997'
            },
            {
                id: 'ld542',
                value: '1998'
            },
            {
                id: 're432',
                value: '1999'
            },
            {
                id: 'md234',
                value: '2000'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690994492840'
        },
        __v: 0,
        explanation: 'The Telecom Regulatory Authority of India (TRAI) was established on 20th February 1997 by Telecom Regulatory Authority of India Act, 1997. Dial-up internet access is a form of internet access that uses the public switched telephone network (PSTN) to establish a connection to an ISP via a telephone line.',
        subtopic: 'Acts and Policies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ca8791c0207ba96ec4ee0c'
        },
        question: 'Which countries conduct exercise Talisman Sabre biennially?',
        options: [
            {
                id: 'pk101',
                value: 'India and Australia'
            },
            {
                id: 'ld542',
                value: 'India and USA'
            },
            {
                id: 're432',
                value: 'Australia and USA'
            },
            {
                id: 'md234',
                value: 'India, Australia and USA'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690994577043'
        },
        __v: 0,
        explanation: 'Exercise Talisman Sabre is a biennial, multinational military exercise between Australia and United States. Fraser Island of Australia is the largest sand island in the world.',
        subtopic: 'Defence Exercises',
        topic: 'Defence'
    },
    {
        _id: {
            $oid: '64ca87a2c0207ba96ec4ee10'
        },
        question: 'Which rock of Australia is listed twice as a UNESCO World Heritage site, both on the cultural and natural lists?',
        options: [
            {
                id: 'pk101',
                value: 'Sydney Rock'
            },
            {
                id: 'ld542',
                value: 'Pilbara rock'
            },
            {
                id: 're432',
                value: 'Uluru rock'
            },
            {
                id: 'md234',
                value: 'Opals Rocks'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690994594894'
        },
        __v: 0,
        explanation: 'The vast rock of Uluru is located in Australia. It is one of the few places on the earth to be listed twice as a UNESCO World Heritage site, both on the cultural and natural lists. 2.5 km of Uluru is underground.',
        subtopic: 'Heritage Sites and UNESCO',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64ca87d2c0207ba96ec4ee14'
        },
        question: 'Under which article of Constitution, President appointed Rohini Commission in 2017 for sub-categorisation of OBCs?',
        options: [
            {
                id: 'pk101',
                value: 'Article 242'
            },
            {
                id: 'ld542',
                value: 'Article 240'
            },
            {
                id: 're432',
                value: 'Article 340'
            },
            {
                id: 'md234',
                value: 'Article 342'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690994642633'
        },
        __v: 0,
        explanation: 'Rohini Commission was constituted in 2017 for sub-categorisation of OBCs. According to Article 340, President can appoint Commission to investigate conditions of socially and educationally backward classes.',
        subtopic: 'The Constitution of India',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ca87f1c0207ba96ec4ee18'
        },
        question: 'In which year, National Investigation Agency got the power to investigate scheduled offences committed outside India?',
        options: [
            {
                id: 'pk101',
                value: '2022'
            },
            {
                id: 'ld542',
                value: '2008'
            },
            {
                id: 're432',
                value: '2011'
            },
            {
                id: 'md234',
                value: '2019'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1690994673457'
        },
        __v: 0,
        explanation: 'National Investigation Agency was found under NIA Act, 2008 after 26/11 attacks. It is a federal counter-terror agency that can take suo-moto cognizance of terrorist activity in any part of India. NIA Amendment Act, 2019 empowered NIA to investigate scheduled offences committed outside India, subject to international treaties and domestic laws of other countries.',
        subtopic: 'National Security',
        topic: 'Defence'
    },
    {
        _id: {
            $oid: '64ca8e98c0207ba96ec4ee1e'
        },
        question: 'What was the venue of Indian National Congress in 1919 when Mohammed Ali and Shaukat Ali were released and it gave a new vigour to the Khilafat movement?',
        options: [
            {
                id: 'pk101',
                value: 'Amritsar'
            },
            {
                id: 'ld542',
                value: 'Calcutta'
            },
            {
                id: 're432',
                value: 'Meerut'
            },
            {
                id: 'md234',
                value: 'Lahore'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690996376388'
        },
        __v: 0,
        explanation: 'Gandhi attended the conference of Khilafat Movement held at Delhi and was elected as President of Khilafat Movement in November 1919. Mohammed Ali and Shaukat Ali were released on eve of Amritsar session of Congress in 1919, it gave a new vigour to the Khilafat movement.',
        subtopic: 'Indian National Congress',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64ca8eafc0207ba96ec4ee22'
        },
        question: 'Which project has been started by ISRO for early warning system in space to detect debris and other hazards to Indian satellites?',
        options: [
            {
                id: 'pk101',
                value: 'Project Gaganyaan'
            },
            {
                id: 'ld542',
                value: 'Project NETRA'
            },
            {
                id: 're432',
                value: 'Project NISAR'
            },
            {
                id: 'md234',
                value: 'Project Gaganyatra'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690996399313'
        },
        __v: 0,
        explanation: 'Project NETRA is an early warning system in space to detect debris and other hazards to Indian satellites. In 2022, ISRO set up the System for Safe and Sustainable Operations Management (IS 4 OM) to continually monitor objects posing collision threats.',
        subtopic: 'Space Research',
        topic: 'Science'
    },
    {
        _id: {
            $oid: '64ca8ebec0207ba96ec4ee26'
        },
        question: 'On which day, the International Tiger Day is celebrated to promote the conservation of the striped cat?',
        options: [
            {
                id: 'pk101',
                value: '30 July'
            },
            {
                id: 'ld542',
                value: '29 July'
            },
            {
                id: 're432',
                value: '28 July'
            },
            {
                id: 'md234',
                value: '28 August'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690996414685'
        },
        __v: 0,
        explanation: '29th July is observed as International Tiger Day (ITD) to promote the conservation of the striped cat. It was established in 2010 at St Petersburg Tiger Summit in Russia to raise awareness about the decline of wild tiger numbers. Critical Tiger Habitats (CTH), also known as core areas of tiger reserves, are identified under the Wild Life Protection Act, 1972',
        subtopic: 'Days and Events',
        topic: 'GK'
    },
    {
        _id: {
            $oid: '64ca8ecec0207ba96ec4ee2a'
        },
        question: 'In which country, Pingali Venkayya, the designer of Indian National Flag, was deployed by British Indian Army?',
        options: [
            {
                id: 'pk101',
                value: 'South Africa'
            },
            {
                id: 'ld542',
                value: 'Egypt'
            },
            {
                id: 're432',
                value: 'Mozambique'
            },
            {
                id: 'md234',
                value: 'Sudan'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1690996430971'
        },
        __v: 0,
        explanation: 'Pingali Venkayya was an Indian freedom fighter. He designed the Indian National Flag in 1921. At the age of 19, Venkayya had enrolled in the British Indian Army and was deployed to South Africa during the Second Boer War.',
        subtopic: 'Indian Army',
        topic: 'Defence'
    },
    {
        _id: {
            $oid: '64ca8ee9c0207ba96ec4ee2e'
        },
        question: 'At which place, first Yoga University was established in 1964 by Swami Satyananda Saraswati?',
        options: [
            {
                id: 'pk101',
                value: 'Meerut'
            },
            {
                id: 'ld542',
                value: 'Pune'
            },
            {
                id: 're432',
                value: 'Munger'
            },
            {
                id: 'md234',
                value: 'Varanasi'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690996457338'
        },
        __v: 0,
        explanation: 'The first Yoga University in the world was established in 1964 by Swami Satyananda Saraswati at Munger in Bihar. Krishnamacharya opened the first hatha yoga school in Mysore in 1920.',
        subtopic: 'Physical Fitness and Yoga',
        topic: 'Sports'
    },
    {
        _id: {
            $oid: '64ca8ef8c0207ba96ec4ee32'
        },
        question: 'In which state, Rani Kamlapati Railway Station is located?',
        options: [
            {
                id: 'pk101',
                value: 'Maharashtra'
            },
            {
                id: 'ld542',
                value: 'Madhya Pradesh'
            },
            {
                id: 're432',
                value: 'Uttar Pradesh'
            },
            {
                id: 'md234',
                value: 'Kolkata'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1690996472638'
        },
        __v: 0,
        explanation: 'Rani Kamlapati Railway Station is located in Habibganj, Madhya Pradesh. It comes under West Central Railway zone (WCR) of the Indian Railways. It has been named after brave and fearless Queen Kamalapati of the Gond kingdom.',
        subtopic: 'Transportation Sector of India',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64ca8f1ac0207ba96ec4ee36'
        },
        question: 'In which year, Bureau of Indian Standards Act was passed for product compliance Quality Control Order?',
        options: [
            {
                id: 'pk101',
                value: '2014'
            },
            {
                id: 'ld542',
                value: '2018'
            },
            {
                id: 're432',
                value: '2016'
            },
            {
                id: 'md234',
                value: '2020'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1690996506594'
        },
        __v: 0,
        explanation: 'Quality Control Order, QCO, is a mandatory certification scheme to ensure compliance with Indian standards applicable to concerned products. It is issued by Central government under the Bureau of Indian Standards (BIS) Act, 2016.',
        subtopic: 'Acts and Policies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64cd0349cab431ce96dea5f4'
        },
        question: 'In which field, The Three Seas Initiative was established by European Union members in 2015?',
        options: [
            {
                id: 'pk101',
                value: 'Conservation of Life under water'
            },
            {
                id: 'ld542',
                value: 'Development of Infrastructure in energy, transport and digital sector'
            },
            {
                id: 're432',
                value: 'Retrieving mineral deposits from deep seabed'
            },
            {
                id: 'md234',
                value: 'Controlling plastic pollution'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1691157321216'
        },
        __v: 0,
        explanation: 'The Three Seas Initiative (3SI) is a forum of twelve European Union member states. The initiative aims to promote cooperation for the development of infrastructure in the energy, transport, and digital sectors. It was established in 2015.',
        subtopic: 'Treaties and Conventions',
        topic: 'International'
    },
    {
        _id: {
            $oid: '64cd0350cab431ce96dea5f8'
        },
        question: 'Who appoints the chairman of Public Accounts Committee, which was introduced in 1921?',
        options: [
            {
                id: 'pk101',
                value: 'Speaker of Lok Sabha'
            },
            {
                id: 'ld542',
                value: 'President of India'
            },
            {
                id: 're432',
                value: 'Prime Minister of India'
            },
            {
                id: 'md234',
                value: 'Leader of Opposition Party'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1691157328008'
        },
        __v: 0,
        explanation: 'Public Accounts Committee was introduced in 1921 after its first mention in the Government of India Act, 1919 also called Montford Reforms. Speaker of Lok Sabha appoints chairman of Committee.',
        subtopic: 'The Union Legislative',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64cd0359cab431ce96dea5fc'
        },
        question: 'On which river, the Polavaram Project is located in Andhra Pradesh?',
        options: [
            {
                id: 'pk101',
                value: 'Damodar'
            },
            {
                id: 'ld542',
                value: 'Krishna'
            },
            {
                id: 're432',
                value: 'Godavari'
            },
            {
                id: 'md234',
                value: 'Brahmaputra'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1691157337211'
        },
        __v: 0,
        explanation: 'The Polavaram Project is multi-purpose irrigation project located on Godavari river in Andhra Pradesh. The project has been started on the recommendations of the Godavari Water Disputer Tribunal (GWDT) in 1980 which identified projects that can be taken up on main Godavari river.',
        subtopic: 'Mineral and Energy Resources',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64cd0378cab431ce96dea600'
        },
        question: 'Which critically endangered species under International Union for Conservation of Nature are found in Rajasthan and Gujarat?',
        options: [
            {
                id: 'pk101',
                value: 'Great Indian Bustard'
            },
            {
                id: 'ld542',
                value: 'Great Indian Rhinoceros'
            },
            {
                id: 're432',
                value: 'West Leaf Monkey'
            },
            {
                id: 'md234',
                value: 'Great Indian Deer'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1691157368845'
        },
        __v: 0,
        explanation: 'Great Indian Bustard found mainly in Rajasthan and Gujarat, has been categorised as critically endangered by the International Union for Conservation of Nature (IUCN). According to the Wildlife Institute of India (WII), in Rajasthan, 18 GIBs die every year after colliding with overhead power lines.',
        subtopic: 'Flora and Fauna',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64cd038bcab431ce96dea604'
        },
        question: 'On which river, the Kaleshwaram Lift Irrigation Project (KLIP) is located?',
        options: [
            {
                id: 'pk101',
                value: 'Godavari'
            },
            {
                id: 'ld542',
                value: 'Krishna'
            },
            {
                id: 're432',
                value: 'Damodar'
            },
            {
                id: 'md234',
                value: 'Brahmaputra'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1691157387755'
        },
        __v: 0,
        explanation: "The Kaleshwaram Lift Irrigation Project (KLIP) is a multi-purpose irrigation project on the Godavari River in Kaleshwaram. Telangana. It is world's largest multi-stage lift irrigation project, its farthest upstream influence is at the confluence of the Pranahita and Godavari rivers.",
        subtopic: 'Water Resources',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64cd0395cab431ce96dea608'
        },
        question: 'Which river has Banas, Mej and Parbati as major tributaries?',
        options: [
            {
                id: 'pk101',
                value: 'Chambal'
            },
            {
                id: 'ld542',
                value: 'Yamuna'
            },
            {
                id: 're432',
                value: 'Brahmaputra'
            },
            {
                id: 'md234',
                value: 'Krishna'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1691157397378'
        },
        __v: 0,
        explanation: 'Chambal river originates from Janapao Hills. The Chambal is the chief tributary of the Yamuna River. Banas, Mej and Parbati are famous tributaries of Chambal river.',
        subtopic: 'Rivers and Climate',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64cd224ccab431ce96dea611'
        },
        question: 'Which mission was started in 2021 to incentivise the commercial production of green hydrogen and make India a net exporter of the fuel?',
        options: [
            {
                id: 'pk101',
                value: 'National Green Hydrogen Mission'
            },
            {
                id: 'ld542',
                value: 'Hydrogen Vista'
            },
            {
                id: 're432',
                value: 'Hydra Shakti'
            },
            {
                id: 'md234',
                value: 'Bharat Green Hydrogen Programme'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1691165260554'
        },
        __v: 0,
        explanation: 'National Green Hydrogen Mission is a program to incentivise the commercial production of green hydrogen and make India a net exporter of the fuel.The mission will replace the fossil fuels based feedstocks with feedstocks based on Green Hydrogen. It was started in 2021.',
        subtopic: 'Industrial Sector',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64cd2254cab431ce96dea615'
        },
        question: 'For which discovery in 1971, Herbart Bormann is known?',
        options: [
            {
                id: 'pk101',
                value: 'Integrated Circuits'
            },
            {
                id: 'ld542',
                value: 'Acid Rain'
            },
            {
                id: 're432',
                value: 'Magnetic Induction'
            },
            {
                id: 'md234',
                value: 'Hadron Collider'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1691165268147'
        },
        __v: 0,
        explanation: 'Acid rain is caused by the release of Sulphur dioxide and nitrogen oxides into the atmosphere, which react with water, oxygen, and other chemicals to form sulphuric and nitric acids that mix with water and other materials before falling to the ground. Herbert Bormann discovered acid rain in 1971.',
        subtopic: 'Inventions and Discoveries',
        topic: 'Science'
    },
    {
        _id: {
            $oid: '64cd2260cab431ce96dea619'
        },
        question: 'In which year, Reza Shah, the king of Iran, changed the name of country from Persia to Iran?',
        options: [
            {
                id: 'pk101',
                value: '1935'
            },
            {
                id: 'ld542',
                value: '1947'
            },
            {
                id: 're432',
                value: '1967'
            },
            {
                id: 'md234',
                value: '1923'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1691165280986'
        },
        __v: 0,
        explanation: 'Chabahar Port is a located in Iran. It is located on Makran coast. In 1935, Reza Shah, king of Iran, changed the name of the country from Persia to Iran. Iran means the land of Aryans.',
        subtopic: 'World History',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64cd226ccab431ce96dea61d'
        },
        question: 'In which country, the UNESCO Intangible Heritage sites like Pahlevani and Zoorkhanei are located?',
        options: [
            {
                id: 'pk101',
                value: 'Egypt'
            },
            {
                id: 'ld542',
                value: 'Russia'
            },
            {
                id: 're432',
                value: 'India'
            },
            {
                id: 'md234',
                value: 'Iran'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1691165292200'
        },
        __v: 0,
        explanation: 'Pahlevani and Zoorkhanei rituals are ancient traditional sports in Iran which is listed on the UNESCO Intangible Heritage. This sport was a traditional system to train warriors in the past. The UNESCO Intangible Cultural Heritage Lists was established in 2008.',
        subtopic: 'Heritage Sites and UNESCO',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64cd2278cab431ce96dea621'
        },
        question: 'Which Indian state has recorded negative decadal growth in Census 2011?',
        options: [
            {
                id: 'pk101',
                value: 'Nagaland'
            },
            {
                id: 'ld542',
                value: 'Sikkim'
            },
            {
                id: 're432',
                value: 'Assam'
            },
            {
                id: 'md234',
                value: 'Manipur'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1691165304026'
        },
        __v: 0,
        explanation: 'Nagaland is the only Indian state which has negative growth rate of -0.58% in census 2011 while Indian population grown at the rate of 17.69% from 2001 to 2011. Dadra and Nagar Haveli had highest decadal growth rate was 55.88%.',
        subtopic: 'Census',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64cd2297cab431ce96dea625'
        },
        question: 'Who was called by the ruler of Jaipur to thwarting an uprising in Bundelkhand and he was also first Archaeological Surveyor of India.?',
        options: [
            {
                id: 'pk101',
                value: 'John Marshall'
            },
            {
                id: 'ld542',
                value: 'James Burgess'
            },
            {
                id: 're432',
                value: 'Alexander Cunningham'
            },
            {
                id: 'md234',
                value: 'Daya Ram Sahni'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1691165335899'
        },
        __v: 0,
        explanation: 'Alexander Cunningham was appointed as the First Archaeological Surveyor of India in 1861. In 1841 Cunningham was made executive engineer to the king of Oudh. In 1842 he was called to serve the army in thwarting an uprising in Bundelkhand by the ruler of Jaipur.',
        subtopic: 'Archaeology',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64cd22a2cab431ce96dea629'
        },
        question: 'Through how many countries, the Tropic of cancer passes?',
        options: [
            {
                id: 'pk101',
                value: '12'
            },
            {
                id: 'ld542',
                value: '18'
            },
            {
                id: 're432',
                value: '20'
            },
            {
                id: 'md234',
                value: '16'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1691165346583'
        },
        __v: 0,
        explanation: 'The Tropic of Cancer passes through 16 countries.The climate of the Tropic of Cancer is generally hot and dry except the easternmost coastal areas where heavy orographic rainfall can occur. It passes through both China and Taiwan.',
        subtopic: 'Physiography and Locations',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64cd22accab431ce96dea62d'
        },
        question: 'At which place, the Harcourt Butler Technological University is located?',
        options: [
            {
                id: 'pk101',
                value: 'Kolkata'
            },
            {
                id: 'ld542',
                value: 'Kanpur'
            },
            {
                id: 're432',
                value: 'Bangalore'
            },
            {
                id: 'md234',
                value: 'Pune'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1691165356607'
        },
        __v: 0,
        explanation: 'Sir Harcourt Butler headed the Indian States Committee formed in 1928. He was an officer of the Indian Civil Service who served as Lieutenant-Governor and Governor of Burma. The Harcourt Butler Technological University is located in Kanpur.',
        subtopic: 'Indian National Movement',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64d84743a715bd95e3c1a450'
        },
        question: 'In which state, Shipki La pass is located?',
        options: [
            {
                id: 'pk101',
                value: 'Arunachal Pradesh'
            },
            {
                id: 'ld542',
                value: 'Uttarakhand'
            },
            {
                id: 're432',
                value: 'Sikkim'
            },
            {
                id: 'md234',
                value: 'Himachal Pradesh'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1691895619908'
        },
        explanation: 'Sutlej river passes through Shipki La pass. Shipki La pass is located in Himachal Pradesh and it provides a road link between Himachal Pradesh and Tibet regions.',
        __v: 0,
        subtopic: 'Mountains and Peaks',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64d8477ea715bd95e3c1a454'
        },
        question: 'Which city was labelled as Urbs Prima in Indis after the opening of the Suez canal in 1869?',
        options: [
            {
                id: 'pk101',
                value: 'Surat'
            },
            {
                id: 'ld542',
                value: 'Calcutta'
            },
            {
                id: 're432',
                value: 'Delhi'
            },
            {
                id: 'md234',
                value: 'Bombay'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1691895678106'
        },
        explanation: 'Bombay was labelled as Urbs Prima in Indis after the opening of the Suez canal in 1869 and its links with the world economy.The Suez Canal is an artificial sea-level waterway in Egypt that connects the Mediterranean Sea to the Red Sea through the Isthmus of Suez, dividing Africa and Asia.',
        __v: 0,
        subtopic: 'Physiography and Locations',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64d84792a715bd95e3c1a458'
        },
        question: 'Who was the first Viceroy of India under British rule?',
        options: [
            {
                id: 'pk101',
                value: 'Lord Linlithgow'
            },
            {
                id: 'ld542',
                value: 'Warren Hastings'
            },
            {
                id: 're432',
                value: 'Viscount Canning'
            },
            {
                id: 'md234',
                value: 'Lord Curzon'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1691895698729'
        },
        explanation: 'Viscount Canning was the first Viceroy of India under British rule. He was Governor-General of India during the Indian Rebellion of 1857 and the first Viceroy of India after the transfer of power from the EIC to the Crown of Queen Victoria in 1858 after the rebellion was crushed.',
        __v: 0,
        subtopic: 'Viceroy and Governor-Generals',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64d8479da715bd95e3c1a45c'
        },
        question: 'Who ended the dual system of administration started by Robert Clive and ruled India for 12 years as Viceroy of India?',
        options: [
            {
                id: 'pk101',
                value: 'Viscount Canning'
            },
            {
                id: 'ld542',
                value: 'Lord Linlithgow'
            },
            {
                id: 're432',
                value: 'Warren Hastings'
            },
            {
                id: 'md234',
                value: 'Lord Curzon'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1691895709805'
        },
        explanation: 'Warren Hastings was first Governor of Bengal. He ended the dual system of administration which was started by Robert Clive. His tenure started in 1773 and ended in 1785.',
        __v: 0,
        subtopic: 'Viceroy and Governor-Generals',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64d847ada715bd95e3c1a460'
        },
        question: 'In which year, Protection of Children from Sexual Offences Act enacted by Indian Parliament?',
        options: [
            {
                id: 'pk101',
                value: '2012'
            },
            {
                id: 'ld542',
                value: '2013'
            },
            {
                id: 're432',
                value: '2014'
            },
            {
                id: 'md234',
                value: '2010'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1691895725621'
        },
        explanation: 'POCSO Act came into effect on 14th November 2012 which was enacted in consequence to India’s ratification of the UN Convention on the Rights of the Child in 1992. POCSO stands for Protection Of Children from Sexual Offences.',
        __v: 0,
        subtopic: 'Acts and Policies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64d847caa715bd95e3c1a464'
        },
        question: 'Which guidelines were started by Supreme Court in 1997 to redress sexual harassment in the workplace?',
        options: [
            {
                id: 'pk101',
                value: 'Janani Guidelines'
            },
            {
                id: 'ld542',
                value: 'Payal Guidelines'
            },
            {
                id: 're432',
                value: 'Vishakha Guidelines'
            },
            {
                id: 'md234',
                value: 'Nimal Guidelines'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1691895754316'
        },
        explanation: 'The Supreme court of India formulated Vishakha guidelines in 1997. It made mandatory for institutions across the country to prevent and redress sexual harassment in the workplace.',
        __v: 0,
        subtopic: 'The Judiciary',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64d847d3a715bd95e3c1a468'
        },
        question: "Who led the 1974 Railway strike as President of All India Railwaymen's Federation?",
        options: [
            {
                id: 'pk101',
                value: 'George Fernandes'
            },
            {
                id: 'ld542',
                value: 'Jayaprakash Narayan'
            },
            {
                id: 're432',
                value: 'A.K Gopalan'
            },
            {
                id: 'md234',
                value: 'Madhav Rao'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1691895763017'
        },
        explanation: "George Fernandes led the 1974 Railway strike He was President of the All India Railwaymen's Federation. It went on for 20 days and it was the largest recorded industrial action in the world. George Fernandes also served as 22nd Defence Minister in 1998 for 6 years.",
        __v: 0,
        subtopic: 'Indian Railways',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64d847f0a715bd95e3c1a46c'
        },
        question: 'Which index is annually released by World Intellectual Property Organization since 2007?',
        options: [
            {
                id: 'pk101',
                value: 'Global Competitiveness Index'
            },
            {
                id: 'ld542',
                value: 'World Innovation Index'
            },
            {
                id: 're432',
                value: 'Intellectual Property Index'
            },
            {
                id: 'md234',
                value: 'Agriculture Orientation Index'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1691895792483'
        },
        explanation: 'The World Innovation Index is released annually by the World Intellectual Property Organization. The index was first released in 2007. It provides detailed metrics about the innovation performance of 131 countries and economies all over the world.',
        __v: 0,
        subtopic: 'Reports and Indices',
        topic: 'GK'
    },
    {
        _id: {
            $oid: '64d84801a715bd95e3c1a470'
        },
        question: 'On which day, the National Handloom Day is observed since 2015 for paying homage to Swadeshi movement?',
        options: [
            {
                id: 'pk101',
                value: '3 August'
            },
            {
                id: 'ld542',
                value: '8 August'
            },
            {
                id: 're432',
                value: '5 August'
            },
            {
                id: 'md234',
                value: '7 August'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1691895809737'
        },
        explanation: 'National Handloom Day is observed on August 7 every year. It was first celebrated on August 7, 2015. The date holds historical significance, paying homage to the Swadeshi Movement launched on August 7, 1905, which championed indigenous industries and, particularly, handloom weavers.',
        __v: 0,
        subtopic: 'Days and Events',
        topic: 'GK'
    },
    {
        _id: {
            $oid: '64dbbe2ea715bd95e3c1a572'
        },
        question: 'In which year, India-United Nations Development Partnership Fund was established for tackling pandemic efforts?',
        options: [
            {
                id: 'pk101',
                value: '2021'
            },
            {
                id: 'ld542',
                value: '2020'
            },
            {
                id: 're432',
                value: '2022'
            },
            {
                id: 'md234',
                value: '2019'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1692122670677'
        },
        explanation: 'The India-United Nations Development Partnership Fund was established in 2020 to help member countries to tackle pandemic efforts on the economy.',
        __v: 0,
        subtopic: 'United Nations',
        topic: 'International'
    },
    {
        _id: {
            $oid: '64dbbe3ca715bd95e3c1a576'
        },
        question: 'Which organisation is headquartered in Lyon, France and was established in 1923 with India as founding member?',
        options: [
            {
                id: 'pk101',
                value: 'WCCF'
            },
            {
                id: 'ld542',
                value: 'MTCR'
            },
            {
                id: 're432',
                value: 'INTERPOL'
            },
            {
                id: 'md234',
                value: 'IMF'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692122684273'
        },
        explanation: 'INTERPOL was established in 1923 and India was one of its founding members. It is headquartered in Lyon, France. The Central Bureau of Investigation(CBI) is one of the ten members of the Interpol Global Academy Network.',
        __v: 0,
        subtopic: 'International Organisations',
        topic: 'International'
    },
    {
        _id: {
            $oid: '64dbbe49a715bd95e3c1a57a'
        },
        question: "Which Rome-based organization of the United Nations is world's largest humanitarian organization in the world?",
        options: [
            {
                id: 'pk101',
                value: 'World Trade Organization'
            },
            {
                id: 'ld542',
                value: 'International Monetary Fund'
            },
            {
                id: 're432',
                value: 'International Food Research Institute'
            },
            {
                id: 'md234',
                value: 'World Food Programme'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1692122697875'
        },
        explanation: "World Food Programme is an international organisation within the United Nations that provides food assistance worldwide. It is the world's largest humanitarian organisation and the leading provider of school meals. It was founded in 1961, and headquartered in Rome and has offices in 80 countries.",
        __v: 0,
        subtopic: 'International Organisations',
        topic: 'International'
    },
    {
        _id: {
            $oid: '64e705e17787e2748b12c6f3'
        },
        question: 'Which hills lie at the southern edge of Malwa plateau?',
        options: [
            {
                id: 'pk101',
                value: 'Vindhayan Hills'
            },
            {
                id: 'ld542',
                value: 'Shevaroy Hills'
            },
            {
                id: 're432',
                value: 'Miri Hills'
            },
            {
                id: 'md234',
                value: 'Lushai Hills'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1692861921307'
        },
        explanation: 'Vindhayan Hills lie at the southern edge of Malwa plateau. The plateau has a drainage system, both, towards the Arabian Sea and the Bay of Bengal. The plateau is covered with black soil.',
        __v: 0,
        subtopic: 'Mountains and Peaks',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e705f57787e2748b12c6f7'
        },
        question: 'In which state Abor Hills, drained by Dibang river, are located?',
        options: [
            {
                id: 'pk101',
                value: 'Arunachal Pradesh'
            },
            {
                id: 'ld542',
                value: 'Mizoram'
            },
            {
                id: 're432',
                value: 'Assam'
            },
            {
                id: 'md234',
                value: 'Manipur'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1692861941909'
        },
        explanation: 'Abor Hills are located in Arunachal Pradesh. It is bordered by the Mishmi Hills and Miri Hills and is drained by the Dibang River, a tributary of the Brahmaputra.',
        __v: 0,
        subtopic: 'States and UTs',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e706027787e2748b12c6fb'
        },
        question: 'Which National park, famous for lion-tailed macaque, is located in Nilgiri Hills of Kerala?',
        options: [
            {
                id: 'pk101',
                value: 'Silent Valley National Park'
            },
            {
                id: 'ld542',
                value: 'Periyar National Park'
            },
            {
                id: 're432',
                value: 'Kuno National Park'
            },
            {
                id: 'md234',
                value: 'Kaziranga National Park'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1692861954835'
        },
        explanation: 'Silent Valley National Park is a national park in Kerala, India. It is located in the Nilgiri hills. It is natural habitat of a species of monkey called lion-tailed macaque.',
        __v: 0,
        subtopic: 'National Parks and Wildlife Sanctuaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e706197787e2748b12c6ff'
        },
        question: 'Where is the Seshachalam Biosphere Reserve located?',
        options: [
            {
                id: 'pk101',
                value: 'Andhra Pradesh'
            },
            {
                id: 'ld542',
                value: 'Tamil Nadu'
            },
            {
                id: 're432',
                value: 'Karnataka'
            },
            {
                id: 'md234',
                value: 'Telangana'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1692861977647'
        },
        explanation: 'Seshachalam Biosphere Reserve is located in Andhra Pradesh. It is part of Eastern Ghats. It was declared a Biosphere Reserve in 2010. It is famous for the Red Sanders trees. It was formed during the Precambrian era',
        __v: 0,
        subtopic: 'National Parks and Wildlife Sanctuaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e706267787e2748b12c703'
        },
        question: 'Which state in India is Nagzira Tiger Reserve located in?',
        options: [
            {
                id: 'pk101',
                value: 'Rajasthan'
            },
            {
                id: 'ld542',
                value: 'Madhya Pradesh'
            },
            {
                id: 're432',
                value: 'Gujarat'
            },
            {
                id: 'md234',
                value: 'Maharashtra'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1692861990529'
        },
        explanation: 'Nagzira Tiger Reserve is located in Bhandara and Gondia districts of Maharashtra. It is a part of Navegaon Nagzira National Park. It contains Navegaon Lake, Nagzira Lake, and Itiadoh Dam',
        __v: 0,
        subtopic: 'National Parks and Wildlife Sanctuaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e7062d7787e2748b12c707'
        },
        question: 'Which atmospheric phenomenon is responsible for the Late and Early Monsoon in South Asia?',
        options: [
            {
                id: 'pk101',
                value: 'El Niño'
            },
            {
                id: 'ld542',
                value: 'Trade Winds'
            },
            {
                id: 're432',
                value: 'Jet Stream'
            },
            {
                id: 'md234',
                value: 'Polar Vortex'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692861997046'
        },
        explanation: 'The Late and Early Monsoon in South Asia occurs due to the Jet Stream. The creation of Polar Vortex and sudden burst of South west monsoon is attributed to Jet Stream',
        __v: 0,
        subtopic: 'Earth Atmosphere',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e7064c7787e2748b12c70b'
        },
        question: 'Why are satellites launched from the eastern Equator?',
        options: [
            {
                id: 'pk101',
                value: 'Shorter travel distance'
            },
            {
                id: 'ld542',
                value: 'Avoiding air resistance'
            },
            {
                id: 're432',
                value: "Initial boost and Earth's rotation"
            },
            {
                id: 'md234',
                value: 'Escaping gravity'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692862028993'
        },
        explanation: 'The satellites are launched from the eastern coast near the Equator to give an initial boost equal to the velocity of the Earth surface and to take advantage of angular momentum of Earth’s rotation.',
        __v: 0,
        subtopic: 'Space Research',
        topic: 'Science'
    },
    {
        _id: {
            $oid: '64e706597787e2748b12c70f'
        },
        question: 'What does El Nino relate to?',
        options: [
            {
                id: 'pk101',
                value: 'Increased rainfall in arid regions'
            },
            {
                id: 'ld542',
                value: 'Cooling of ocean temperatures'
            },
            {
                id: 're432',
                value: 'Warming of ocean surface temperatures'
            },
            {
                id: 'md234',
                value: 'Strengthening of air circulation'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692862041545'
        },
        explanation: 'El Nino is a climate pattern associated with the warming of ocean surface temperatures in the central and eastern tropical Pacific Ocean. Generally, El Nino occurs for an average of 2 to 7 years. It causes the weakening of walker circulation, which disrupts the normal flow of air and moisture.',
        __v: 0,
        subtopic: 'Ocean and Ocean Currents',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e7065f7787e2748b12c713'
        },
        question: 'Which type of soil is suitable for saffron production?',
        options: [
            {
                id: 'pk101',
                value: 'Sandy soils'
            },
            {
                id: 'ld542',
                value: 'Clayey soils'
            },
            {
                id: 're432',
                value: 'Karewa soils'
            },
            {
                id: 'md234',
                value: 'Swampy soils'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692862047319'
        },
        explanation: 'Saffron is the most expensive spice, known for its aroma and colour. It is used for flavouring, colouring, and in medicinal industries. Saffron production is restricted to well-drained Karewa soils in the Union Territory of Jammu and Kashmir',
        __v: 0,
        subtopic: 'Agriculture Sector',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e706637787e2748b12c717'
        },
        question: 'Which region is known as the Saffron Bowl of Kashmir?',
        options: [
            {
                id: 'pk101',
                value: 'Kishtwar'
            },
            {
                id: 'ld542',
                value: 'Budgam'
            },
            {
                id: 're432',
                value: 'Srinagar'
            },
            {
                id: 'md234',
                value: 'Pampore'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1692862051896'
        },
        explanation: 'Pampore region in Jammu and Kashmir is known as the Saffron Bowl of Kashmir. The areas of Budgam, Srinagar and Kishtwar districts are famous for saffron production. India produces 7% of the total saffron in the world.',
        __v: 0,
        subtopic: 'Agriculture Sector',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e7066b7787e2748b12c71b'
        },
        question: "Which Ministry is responsible for the scheme 'Survey of Villages and Mapping with Improvised Technology in Village areas'?",
        options: [
            {
                id: 'pk101',
                value: 'Ministry of Urban Development'
            },
            {
                id: 'ld542',
                value: 'Ministry of Rural Development'
            },
            {
                id: 're432',
                value: 'Ministry of Agriculture'
            },
            {
                id: 'md234',
                value: 'Ministry of Panchayati Raj'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1692862059715'
        },
        explanation: 'The Survey of Villages and Mapping with Improvised Technology in Village areas is a Central sector scheme of the Ministry of Panchayati Raj. E-Property cards have been distributed under the scheme to the property owners.',
        __v: 0,
        subtopic: 'Constitutional Bodies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64e706747787e2748b12c71f'
        },
        question: 'When was the National Mission on Edible Oils - Oil Palm established?',
        options: [
            {
                id: 'pk101',
                value: 'September 2022'
            },
            {
                id: 'ld542',
                value: 'July 2020'
            },
            {
                id: 're432',
                value: 'August 2021'
            },
            {
                id: 'md234',
                value: 'June 2019'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692862068249'
        },
        explanation: 'The National Mission on Edible Oils - Oil Palm was established in August 2021 to reduce edible oil imports. The mission emphasizes the expansion of oil palm cultivation, aiming to augment crude palm oil production and ease the country’s import burden.',
        __v: 0,
        subtopic: 'Agriculture Sector',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e7067a7787e2748b12c723'
        },
        question: 'Which body of water does the Sulina Channel connect to?',
        options: [
            {
                id: 'pk101',
                value: 'Caspian Sea'
            },
            {
                id: 'ld542',
                value: 'Mediterranean Sea'
            },
            {
                id: 're432',
                value: 'Black Sea'
            },
            {
                id: 'md234',
                value: 'Baltic Sea'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692862074320'
        },
        explanation: 'The Sulina Channel is a distributary of the Danube river that connects Ukrainian ports on the river to the Black Sea. It lies completely within the borders of Romania, which is a NATO member.',
        __v: 0,
        subtopic: 'Water Resources',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e706827787e2748b12c727'
        },
        question: 'What is the approximate length of the Danube River?',
        options: [
            {
                id: 'pk101',
                value: '2,850 km'
            },
            {
                id: 'ld542',
                value: '1,500 km'
            },
            {
                id: 're432',
                value: '4,200 km'
            },
            {
                id: 'md234',
                value: '3,300 km'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1692862082741'
        },
        explanation: 'The Danube River is the second-longest river in Europe, with a length of 2,850 km. It rises in the Black Forest mountains of western Germany and flows east to the Black Sea, forming the Danube Delta on its western coast.',
        __v: 0,
        subtopic: 'Water Resources',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e7068d7787e2748b12c72b'
        },
        question: 'What is the purpose of the Nord Stream 2 pipeline?',
        options: [
            {
                id: 'pk101',
                value: 'Carrying water resources'
            },
            {
                id: 'ld542',
                value: 'Supplying oil'
            },
            {
                id: 're432',
                value: 'Transporting natural gas'
            },
            {
                id: 'md234',
                value: 'Creating a communication channel'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692862093854'
        },
        explanation: 'The Nord Stream 2 pipeline is a 1200 km pipeline that runs from Ust-Luga in Russia to Greifswald in Germany through the Baltic Sea. Nord Stream 2 runs parallel to an existing gas pipeline, Nord Stream 1, which has been working since 2011.',
        __v: 0,
        subtopic: 'Transportation Sector of India',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e706947787e2748b12c72f'
        },
        question: 'Which two countries share Lake Titicaca?',
        options: [
            {
                id: 'pk101',
                value: 'Brazil and Venezuela'
            },
            {
                id: 'ld542',
                value: 'Colombia and Ecuador'
            },
            {
                id: 're432',
                value: 'Chile and Argentina'
            },
            {
                id: 'md234',
                value: 'Bolivia and Peru'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1692862100010'
        },
        explanation: 'Lake Titicaca is the largest freshwater lake in South America and the highest of the world’s largest lakes. It lies on the border between Bolivia and Peru.',
        __v: 0,
        subtopic: 'Lakes and Glaciers',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e753b87787e2748b12c857'
        },
        question: 'When was the second Battle of Tarain fought?',
        options: [
            {
                id: 'pk101',
                value: '1181'
            },
            {
                id: 'ld542',
                value: '1192'
            },
            {
                id: 're432',
                value: '1205'
            },
            {
                id: 'md234',
                value: '1210'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1692881848830'
        },
        explanation: 'The second Battle of Tarain was fought in 1192, a year after the first battle. Muhammad Ghori emerged victorious after defeating Prithviraj Chauhan.',
        __v: 0,
        subtopic: 'Battles and Wars',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64e753bd7787e2748b12c85b'
        },
        question: 'Who emerged victorious in the Battle of Khanwa?',
        options: [
            {
                id: 'pk101',
                value: 'Babar'
            },
            {
                id: 'ld542',
                value: 'Rana Sanga'
            },
            {
                id: 're432',
                value: 'Akbar'
            },
            {
                id: 'md234',
                value: 'Aurangzeb'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1692881853666'
        },
        explanation: 'The Battle of Khanwa was fought between Babur and Rana Sanga in 1527. Babar emerged victorious in this battle.',
        __v: 0,
        subtopic: 'Battles and Wars',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64e753c17787e2748b12c85f'
        },
        question: 'Where is Mohenjodaro located, which is also called Mound of the Dead?',
        options: [
            {
                id: 'pk101',
                value: 'Rajasthan'
            },
            {
                id: 'ld542',
                value: 'Punjab'
            },
            {
                id: 're432',
                value: 'Sindh'
            },
            {
                id: 'md234',
                value: 'Gujarat'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692881857527'
        },
        explanation: 'Mohenjodaro is located in Sindh. It is also referred to as the Mound of the Dead. The famous discoveries of Mohenjodaro are the Great Bath and the Bronze dancing girl.',
        __v: 0,
        subtopic: 'Harappa Civilization',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64e753c47787e2748b12c863'
        },
        question: 'What was the primary purpose of the Great Bath of Mohenjodaro?',
        options: [
            {
                id: 'pk101',
                value: 'Ritual bathing'
            },
            {
                id: 'ld542',
                value: 'Cooking food'
            },
            {
                id: 're432',
                value: 'Textile production'
            },
            {
                id: 'md234',
                value: 'Storage of goods'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1692881860510'
        },
        explanation: 'The Great Bath of Mohenjodaro served ritual bathing. No temples or ritual structures except the Great Bath have been found at Harappa. It is likely that no priests ruled in Harappa.',
        __v: 0,
        subtopic: 'Harappa Civilization',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64e753c97787e2748b12c867'
        },
        question: 'Where is Sohgaura located?',
        options: [
            {
                id: 'pk101',
                value: 'On the Rapti river in Gorakhpur'
            },
            {
                id: 'ld542',
                value: 'Beside the Yamuna river in Agra'
            },
            {
                id: 're432',
                value: 'In the Thar Desert of Rajasthan'
            },
            {
                id: 'md234',
                value: 'On the coastline of Kerala'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1692881865153'
        },
        explanation: 'Sohgaura is an ancient site located on the banks of the Rapti river in Gorakhpur. The famous Sohgaura copper plate inscription was discovered in Sohagaura. The script was written in Prakrit in Brahmi script.',
        __v: 0,
        subtopic: 'Harappa Civilization',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64e753cc7787e2748b12c86b'
        },
        question: 'What is the year associated with the Durand Agreement?',
        options: [
            {
                id: 'pk101',
                value: '1893'
            },
            {
                id: 'ld542',
                value: '1847'
            },
            {
                id: 're432',
                value: '1905'
            },
            {
                id: 'md234',
                value: '1920'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1692881868625'
        },
        explanation: 'The Durand Agreement of 1893 demarcated the Indo-Afghan border. At present, it is the international border between Pakistan and Afghanistan with a length of 2600 km.',
        __v: 0,
        subtopic: 'Geographical Boundaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e753d17787e2748b12c86f'
        },
        question: 'What was the position held by Sir Henry Mortimer Durand during the negotiations for the Durand Line Agreement?',
        options: [
            {
                id: 'pk101',
                value: 'Trade Minister'
            },
            {
                id: 'ld542',
                value: 'Ambassador to Afghanistan'
            },
            {
                id: 're432',
                value: 'Foreign Secretary'
            },
            {
                id: 'md234',
                value: 'Colonial Administrator'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692881873147'
        },
        explanation: 'Sir Henry Mortimer Durand was foreign secretary of the British government. He negotiated with Abdur Rahman of Afghan kingdom about border between Indian and Afghan Empire which resulted in Durand Line Agreement.',
        __v: 0,
        subtopic: 'Geographical Boundaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64e753d67787e2748b12c873'
        },
        question: 'When was the Treaty of Gandamak signed marking the end of first phase of Second Anglo-Afghan war?',
        options: [
            {
                id: 'pk101',
                value: 'August 15, 1868'
            },
            {
                id: 'ld542',
                value: 'March 12, 1881'
            },
            {
                id: 're432',
                value: 'May 26, 1879'
            },
            {
                id: 'md234',
                value: 'November 5, 1892'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692881878967'
        },
        explanation: "The Treaty of Gandamak officially ended the first phase of the Second Anglo-Afghan War. It was signed on May 26, 1879. Emir Mohammad Yaqub Khan ceded various frontier areas and Afghanistan's control of its foreign affairs to Britain.",
        __v: 0,
        subtopic: 'Battles and Wars',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64e753dc7787e2748b12c877'
        },
        question: 'What did Shah Alam II grant through a farman on August 12, 1765?',
        options: [
            {
                id: 'pk101',
                value: 'Diwani rights'
            },
            {
                id: 'ld542',
                value: 'Trade monopoly'
            },
            {
                id: 're432',
                value: 'Military control'
            },
            {
                id: 'md234',
                value: 'Tax exemption'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1692881884779'
        },
        explanation: 'Shah Alam II issued a farman granting Diwani rights on August 12, 1765. He granted Diwani of Bengal, Bihar and Orissa to the company. The Diwani, also called revenue collecting rights, gave absolute control over the resources of these states.',
        __v: 0,
        subtopic: 'British Rule in India',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64e753e17787e2748b12c87b'
        },
        question: 'Which ruler of the Satavahana dynasty performed two horse sacrifices known as Asvamedha Yagna?',
        options: [
            {
                id: 'pk101',
                value: 'Harsha'
            },
            {
                id: 'ld542',
                value: 'Pulakeshin II'
            },
            {
                id: 're432',
                value: 'Chandragupta Maurya'
            },
            {
                id: 'md234',
                value: 'Gautamiputra Satakarni'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1692881889654'
        },
        explanation: 'Gautamiputra Satakarni was the greatest ruler of the Satavahana dynasty. He performed two horse sacrifices, known as Asvamedha Yagna, indicative of his imperial position.',
        __v: 0,
        postedAt: {
            $numberLong: '1693307524881'
        },
        subtopic: 'Early Medieval Period',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64e753e67787e2748b12c87f'
        },
        question: 'From which ancient language is the Hindi language developed?',
        options: [
            {
                id: 'pk101',
                value: 'Apabhramsa'
            },
            {
                id: 'ld542',
                value: 'Sanskrit'
            },
            {
                id: 're432',
                value: 'Pali'
            },
            {
                id: 'md234',
                value: 'Prakrit'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1692881894659'
        },
        explanation: 'Apabhramsa language is an umbrella term which means dialects other than Sanskrit. Hindi language is developed from the ancient Apabhramsa language.',
        __v: 0,
        subtopic: 'Early Medieval Period',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64e753eb7787e2748b12c883'
        },
        question: 'In which year was the Lottery Committee established?',
        options: [
            {
                id: 'pk101',
                value: '1833'
            },
            {
                id: 'ld542',
                value: '1825'
            },
            {
                id: 're432',
                value: '1817'
            },
            {
                id: 'md234',
                value: '1805'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692881899361'
        },
        explanation: 'The Lottery Committee was established in 1817. It assisted the government in the task of town planning in Calcutta. The committee was named because funds for town improvement were raised through public lotteries.',
        __v: 0,
        subtopic: 'British Rule in India',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64e753ee7787e2748b12c887'
        },
        question: "In which text does the verse 'Vasudhaiva Kutumbakam' find mention?",
        options: [
            {
                id: 'pk101',
                value: 'Atharva Veda\n'
            },
            {
                id: 'ld542',
                value: 'Bhagavad Gita'
            },
            {
                id: 're432',
                value: 'Ramayana'
            },
            {
                id: 'md234',
                value: 'Maha Upanishad'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1692881902902'
        },
        explanation: 'Vasudhaiva Kutumbakam is a philosophy that inculcates an understanding that the whole world is one family and the verse finds mention in Maha Upanishad.',
        __v: 0,
        postedAt: {
            $numberLong: '1693307519378'
        },
        subtopic: 'The Vedic Era',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64e753f37787e2748b12c88b'
        },
        question: 'Who introduced the office of Collector and was associated with the establishment of the Supreme Court of Calcutta?',
        options: [
            {
                id: 'pk101',
                value: 'Lord Cornwallis'
            },
            {
                id: 'ld542',
                value: 'Robert Clive'
            },
            {
                id: 're432',
                value: 'Warren Hastings'
            },
            {
                id: 'md234',
                value: 'William Bentinck'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1692881907985'
        },
        explanation: 'Warren Hastings introduced the office of Collector. He introduced the Regulation Act, 1773 and Pitts India Act, 1784. The Supreme Court of Calcutta was established in 1774 in his tenure.',
        __v: 0,
        postedAt: {
            $numberLong: '1693306888121'
        },
        subtopic: 'The Judiciary',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64e753f87787e2748b12c88f'
        },
        question: 'Through which Act was the East India Company appointed as the centralised Regulatory Authority for India?',
        options: [
            {
                id: 'pk101',
                value: 'Regulation Act, 1773'
            },
            {
                id: 'ld542',
                value: "Pitt's India Act"
            },
            {
                id: 're432',
                value: 'Charter Act of 1813'
            },
            {
                id: 'md234',
                value: 'Government of India Act, 1858'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1692881912420'
        },
        explanation: "East India Company was appointed as the centralised Regulatory Authority through India by Pitt's India Act, which was accepted in British Parliament in England on 13 August, 1784.",
        __v: 0,
        postedAt: {
            $numberLong: '1693307517407'
        },
        subtopic: 'Acts and Policies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64e753fc7787e2748b12c893'
        },
        question: 'Who was the founder of the Vakataka dynasty?',
        options: [
            {
                id: 'pk101',
                value: 'Vindhyashakti I'
            },
            {
                id: 'ld542',
                value: 'Gautamiputra Satakarni'
            },
            {
                id: 're432',
                value: 'Samudragupta'
            },
            {
                id: 'md234',
                value: 'Skandagupta'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1692881916855'
        },
        explanation: 'Vindhyashakti I was the founder of Vakataka dynasty who ruled from Purika. He performed a number of Vedic sacrifices and revived Brahminical rituals.',
        __v: 0,
        postedAt: {
            $numberLong: '1693190720278'
        },
        subtopic: 'Early Medieval Period',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64ee181dcbc989e9c16d03ed'
        },
        question: 'Which article of the Indian Constitution provides the power to conduct free and fair elections?',
        options: [
            {
                id: 'pk101',
                value: 'Article 324'
            },
            {
                id: 'ld542',
                value: 'Article 356'
            },
            {
                id: 're432',
                value: 'Article 370'
            },
            {
                id: 'md234',
                value: 'Article 301'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693325341501'
        },
        explanation: 'The Election Commission of India was established in 1950. Article 324 of the Constitution provides the power to conduct free and fair elections. The number of Election Commission members is fixed by the President.',
        __v: 0,
        subtopic: 'History of Constitution',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ee1824cbc989e9c16d03f1'
        },
        question: 'In which year was the Coastal Aquaculture Authority Act passed?',
        options: [
            {
                id: 'pk101',
                value: '2005'
            },
            {
                id: 'ld542',
                value: '1998'
            },
            {
                id: 're432',
                value: '2010'
            },
            {
                id: 'md234',
                value: '2015\n'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693325348370'
        },
        explanation: 'The Coastal Aquaculture Authority Act was passed in 2005. Coastal Aquaculture refers to the practice of cultivating and rearing aquatic organisms in marine or brackish water environments along the coastlines or in estuaries.',
        __v: 0,
        subtopic: 'Acts and Policies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ee182acbc989e9c16d03f5'
        },
        question: 'Which act is responsible for regulating the cinema in India?',
        options: [
            {
                id: 'pk101',
                value: 'Cinematographic Act of 1962'
            },
            {
                id: 'ld542',
                value: 'Cinema Act, 1962'
            },
            {
                id: 're432',
                value: 'Cinematographic Act of 1952'
            },
            {
                id: 'md234',
                value: 'Cinema Act, 1959'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693325354938'
        },
        explanation: 'The cinema in India is regulated by the Cinematographic Act of 1952. It establishes a regulating body named Central Board of Film Certification for the certification of cinematography films, for the exhibition of movies, and to regulate those exhibitions.',
        __v: 0,
        subtopic: 'Acts and Policies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ee1831cbc989e9c16d03f9'
        },
        question: 'In which year, the Telecom Regulatory Authority of India act was passed?',
        options: [
            {
                id: 'pk101',
                value: '1994'
            },
            {
                id: 'ld542',
                value: '1998'
            },
            {
                id: 're432',
                value: '1997'
            },
            {
                id: 'md234',
                value: '1995'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693325361296'
        },
        explanation: 'The telecommunication sector is regulated through the Telecom Regulatory Authority of India Act, 1997. It protects the interests of service providers and consumers.',
        __v: 0,
        subtopic: 'Acts and Policies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ee1838cbc989e9c16d03fd'
        },
        question: 'Which acts regulate the print media in India?',
        options: [
            {
                id: 'pk101',
                value: 'Journalistic Integrity Act of 2000'
            },
            {
                id: 'ld542',
                value: 'Media Ethics and Standards Act of 1995'
            },
            {
                id: 're432',
                value: 'Print Media Control Act of 1985'
            },
            {
                id: 'md234',
                value: 'Press and Registration of Books Act of 1867'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693325368989'
        },
        explanation: 'The print media in India is regulated by the Press and Registration of Books Act of 1867 and the Press Council Act of 1978. Press and Registration of Books provides for the registration, regulation and preservation of every copy of books and newspapers printed in India.',
        __v: 0,
        subtopic: 'Acts and Policies',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ee1842cbc989e9c16d0401'
        },
        question: 'When was the Members of Parliament Local Area Development Scheme launched?',
        options: [
            {
                id: 'pk101',
                value: '1987'
            },
            {
                id: 'ld542',
                value: '2000'
            },
            {
                id: 're432',
                value: '1993'
            },
            {
                id: 'md234',
                value: '2010'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693325378265'
        },
        explanation: 'The Members of Parliament Local Area Development Scheme was launched in 1993. The MPs receive 5 crore rupees every year for development works.',
        __v: 0,
        subtopic: 'Local Self Government',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ee1849cbc989e9c16d0405'
        },
        question: "In which case did the Supreme Court of India adjudge that the proclamation of the President's rule under Article 356 of the Constitution of India is subject to judicial review?",
        options: [
            {
                id: 'pk101',
                value: 'S.R Bommai and others vs Union of India'
            },
            {
                id: 'ld542',
                value: 'Keshavananda Bharati vs State of Kerala'
            },
            {
                id: 're432',
                value: 'Indira Gandhi vs Raj Narain'
            },
            {
                id: 'md234',
                value: 'Minerva Mills Ltd. vs Union of India'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693325385639'
        },
        explanation: 'In S.R Bommai and others vs Union of India, Supreme Court of India adjudge that the proclamation of the President’s ruler under Article 356 of the Constitution of India is subject to judicial review.',
        __v: 0,
        subtopic: 'The Judiciary',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ee184fcbc989e9c16d0409'
        },
        question: 'In which year was the Comptroller and Auditor General of India (Duties, Powers, and Conditions of Service) Act, 1971 passed?',
        options: [
            {
                id: 'pk101',
                value: '1971'
            },
            {
                id: 'ld542',
                value: '1980'
            },
            {
                id: 're432',
                value: '1965'
            },
            {
                id: 'md234',
                value: '1995'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693325391672'
        },
        explanation: 'In 1971, the central government passed the Comptroller and Auditor General of India (Duties, Powers, and Conditions of Service) Act, 1971, outlining the responsibilities, authority, and terms of service for the CAG.',
        __v: 0,
        subtopic: 'The Union Executive',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ee1859cbc989e9c16d040d'
        },
        question: 'When was the PM-JAY health insurance scheme launched?',
        options: [
            {
                id: 'pk101',
                value: 'December 2017'
            },
            {
                id: 'ld542',
                value: 'February 2018'
            },
            {
                id: 're432',
                value: 'October 2019'
            },
            {
                id: 'md234',
                value: 'April 2016'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1693325401630'
        },
        explanation: 'The PM-JAY is the world’s largest health insurance scheme fully financed by the government and launched in February 2018. The National Health Authority (NHA) has been constituted for the effective implementation of PM-JAY.',
        __v: 0,
        subtopic: 'Government Schemes',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ee1861cbc989e9c16d0411'
        },
        question: 'In which section of the Criminal Procedure Code is the provision related to the First Information Report (FIR) found?',
        options: [
            {
                id: 'pk101',
                value: 'Section 376'
            },
            {
                id: 'ld542',
                value: 'Section 302'
            },
            {
                id: 're432',
                value: 'Section 420'
            },
            {
                id: 'md234',
                value: 'Section 154'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693325409018'
        },
        explanation: 'The First Information Report is a written document prepared by police. It contains information about any cognizable offence under Section 154 of Criminal Procedure Code. The term FIR, however, is not mentioned either in the Indian Penal Code or the Criminal Procedure Code.',
        __v: 0,
        subtopic: 'The Union Executive',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ee2082cbc989e9c16d0419'
        },
        question: 'When was the World Trade Organization (WTO) founded?',
        options: [
            {
                id: 'pk101',
                value: '1 January 1995'
            },
            {
                id: 'ld542',
                value: '1 January 1985'
            },
            {
                id: 're432',
                value: '1 January 2000'
            },
            {
                id: 'md234',
                value: '1 January 1990'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693327490616'
        },
        explanation: 'The World Trade Organization was founded on 1 January 1995. In 1998, the WTO members came out with a Declaration on Global Electronic Commerce, under which countries have decided not to impose customs duties on e-commerce transactions.',
        __v: 0,
        subtopic: 'International Organisations',
        topic: 'International'
    },
    {
        _id: {
            $oid: '64ee20a9cbc989e9c16d041d'
        },
        question: 'When was the G20 Digital Innovation Alliance (G20-DIA) launched?',
        options: [
            {
                id: 'pk101',
                value: 'January 2021'
            },
            {
                id: 'ld542',
                value: 'December 2022'
            },
            {
                id: 're432',
                value: 'November 2020'
            },
            {
                id: 'md234',
                value: 'October 2019'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1693327529972'
        },
        explanation: "The G20 Digital Innovation Alliance (G20-DIA) was launched by the Ministry of Electronics and Information Technology (MeitY) as part of India's G20 Presidency in December 2022.",
        __v: 0,
        subtopic: 'International Organisations',
        topic: 'International'
    },
    {
        _id: {
            $oid: '64ee20b2cbc989e9c16d0421'
        },
        question: 'When was the Gulf Cooperation Council (GCC) established?',
        options: [
            {
                id: 'pk101',
                value: 'September 1978'
            },
            {
                id: 'ld542',
                value: 'April 1979'
            },
            {
                id: 're432',
                value: 'June 1983'
            },
            {
                id: 'md234',
                value: 'May 1981'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693327538909'
        },
        explanation: 'The Gulf Cooperation Council was established in Riyadh in May, 1981. The six countries are Saudi Arabia, Kuwait, the United Arab Emirates, Qatar, Bahrain, and Oman.',
        __v: 0,
        subtopic: 'International Organisations',
        topic: 'International'
    },
    {
        _id: {
            $oid: '64ee20c2cbc989e9c16d0425'
        },
        question: 'What is the objective of the Luna-25 mission?',
        options: [
            {
                id: 'pk101',
                value: 'Studying the composition of the polar regolith'
            },
            {
                id: 'ld542',
                value: "Exploring the Moon's equatorial region"
            },
            {
                id: 're432',
                value: "Searching for water ice in the Moon's subsurface"
            },
            {
                id: 'md234',
                value: 'Investigating lunar seismic activity'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693327554998'
        },
        explanation: 'Luna-25 is a Russian lunar lander mission launched to make a soft landing on the lunar south pole. Its mission is to study the composition of the polar regolith and dust components of lunar polar exosphere.',
        __v: 0,
        subtopic: 'Space Research',
        topic: 'Science'
    },
    {
        _id: {
            $oid: '64ee20cfcbc989e9c16d0429'
        },
        question: 'Who is the only Indian citizen to ever fly to space?',
        options: [
            {
                id: 'pk101',
                value: 'Sunita Williams'
            },
            {
                id: 'ld542',
                value: 'Kalpana Chawla'
            },
            {
                id: 're432',
                value: 'Rakesh Sharma'
            },
            {
                id: 'md234',
                value: 'Vikram Sarabhai'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693327567730'
        },
        explanation: 'Rakesh Sharma is the only Indian citizen to ever fly to space. He flew to the Salyut 7 space station on a Soyuz rocket in 1984 as part of the USSR’s Interkosmos program.',
        __v: 0,
        subtopic: 'Space Research',
        topic: 'Science'
    },
    {
        _id: {
            $oid: '64ee20d8cbc989e9c16d042d'
        },
        question: 'What is the role of FIMMDA?',
        options: [
            {
                id: 'pk101',
                value: 'Issuer of government bonds'
            },
            {
                id: 'ld542',
                value: 'Regulatory authority for financial markets'
            },
            {
                id: 're432',
                value: 'Voluntary market body for the bond, money and derivatives market'
            },
            {
                id: 'md234',
                value: 'Credit rating agency'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693327576430'
        },
        explanation: 'The Fixed Income Money Market and Derivatives Association of India, FIMMDA is a company under the Companies Act, 1956. It is a voluntary market body for the bond, money and derivatives market.',
        __v: 0,
        subtopic: 'Planning',
        topic: 'Economy'
    },
    {
        _id: {
            $oid: '64ee2106cbc989e9c16d0431'
        },
        question: 'When was the Department of Science and Technology (DST) founded?',
        options: [
            {
                id: 'pk101',
                value: '26th January 1950'
            },
            {
                id: 'ld542',
                value: '15th August 1947'
            },
            {
                id: 're432',
                value: '3rd May 1971'
            },
            {
                id: 'md234',
                value: '2nd October 1969'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693327622551'
        },
        explanation: 'The foundation of Department of Science and Technology was laid on 3rd May 1971 along the model of National Science Foundation (NSF), USA. It provides funding and also makes policies and co-ordinates scientific work with other countries.',
        __v: 0,
        subtopic: 'Science Organisations',
        topic: 'Science'
    },
    {
        _id: {
            $oid: '64ee2115cbc989e9c16d0435'
        },
        question: 'Which training institute was renamed as Lal Bahadur Shastri National Academy of Administration (LBSNAA) in July 1973?',
        options: [
            {
                id: 'pk101',
                value: 'National Academy of Administration'
            },
            {
                id: 'ld542',
                value: 'Indian Institute of Public Administration'
            },
            {
                id: 're432',
                value: 'Indian Institute of Management, Ahmedabad'
            },
            {
                id: 'md234',
                value: 'Administrative Staff College of India'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693327637800'
        },
        explanation: 'National Academy of Administration is a training institute on public administration in Mussoorie in Uttarakhand. It was renamed Lal Bahadur Shastri National Academy of Administration (LBSNAA) in July 1973.',
        __v: 0,
        subtopic: 'Public Administration',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ee212bcbc989e9c16d0439'
        },
        question: 'Who originated as a unique category tribal group based on the findings of the Dhebar Commission in 1973?',
        options: [
            {
                id: 'pk101',
                value: 'Scheduled Tribes'
            },
            {
                id: 'ld542',
                value: 'Particularly Vulnerable Tribal Group'
            },
            {
                id: 're432',
                value: 'Scheduled Castes'
            },
            {
                id: 'md234',
                value: 'Backward Classes'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1693327659748'
        },
        explanation: 'The Particularly Vulnerable Tribal Group, originating as a unique category tribal group, emerged from the findings of the Dhebar Commission in 1973. PVTG are identified as the most vulnerable inhabitants residing in the less developed regions of India.',
        __v: 0,
        subtopic: 'Tribal Areas',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64ee2154cbc989e9c16d043d'
        },
        question: 'When is World Elephant Day celebrated?',
        options: [
            {
                id: 'pk101',
                value: '12 August'
            },
            {
                id: 'ld542',
                value: '22 March'
            },
            {
                id: 're432',
                value: '7 June'
            },
            {
                id: 'md234',
                value: '3 October'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693327700000'
        },
        explanation: 'World Elephant Day is celebrated on 12 August every year. As per 2017 data, India has 29,964 elephants. The Day was initiated in 2012 to bring awareness to the disturbing situations of African and Asian elephants.',
        __v: 0,
        subtopic: 'Days and Events',
        topic: 'GK'
    },
    {
        _id: {
            $oid: '64ee215ccbc989e9c16d0441'
        },
        question: 'When was the Monitoring of Illegal Killing of Elephants (MIKE) Programme started in South Asia?',
        options: [
            {
                id: 'pk101',
                value: '2010'
            },
            {
                id: 'ld542',
                value: '1997'
            },
            {
                id: 're432',
                value: '2003'
            },
            {
                id: 'md234',
                value: '2001'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693327708275'
        },
        explanation: 'The Monitoring of Illegal Killing of Elephants (MIKE) Programme was started in South Asia in 2003. The MIKE Programme was established by the CITES by Resolution 10.10 adopted at the tenth Conference of the Parties in 1997.',
        __v: 0,
        subtopic: 'Wildlife Conservation',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64ee2173cbc989e9c16d0445'
        },
        question: 'Which Indian state has the highest number of elephants as per the 2017 Elephant census?',
        options: [
            {
                id: 'pk101',
                value: 'Tamil Nadu'
            },
            {
                id: 'ld542',
                value: 'Assam'
            },
            {
                id: 're432',
                value: 'Kerala'
            },
            {
                id: 'md234',
                value: 'Karnataka'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693327731618'
        },
        explanation: 'As per Elephant census in 2017, Karnataka has the highest number of elephants, 6049. Elephants have the longest-known gestational (pregnancy) period of all mammals, lasting up to 680 days (22 months).',
        __v: 0,
        subtopic: 'Wildlife Conservation',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64ee217fcbc989e9c16d0449'
        },
        question: 'Who founded the World Elephant Society and initiated the celebration of World Elephant Day?',
        options: [
            {
                id: 'pk101',
                value: 'Patricia Sims'
            },
            {
                id: 'ld542',
                value: 'David Attenborough'
            },
            {
                id: 're432',
                value: 'Jane Goodall'
            },
            {
                id: 'md234',
                value: 'Richard Leakey'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693327743358'
        },
        explanation: 'In 2012, Patricia Sims founded an organisation called the World Elephant Society. It created awareness about the dangers confronted by elephants. Since 2012, World Elephant Day is celebrated on 12 August every year.',
        __v: 0,
        subtopic: 'Wildlife Conservation',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64ee218fcbc989e9c16d044d'
        },
        question: 'When is World Humanitarian Day observed?',
        options: [
            {
                id: 'pk101',
                value: '22nd March'
            },
            {
                id: 'ld542',
                value: '5th June'
            },
            {
                id: 're432',
                value: '10th December'
            },
            {
                id: 'md234',
                value: '19th August'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693327759600'
        },
        explanation: 'The World Humanitarian Day has been observed on 19th August every year since 2009. In 2009, the UN General Assembly announced the day in remembrance of the bomb attack on Canal Hotel in Baghdad, Iraq.',
        __v: 0,
        subtopic: 'Days and Events',
        topic: 'GK'
    },
    {
        _id: {
            $oid: '64ee221fcbc989e9c16d0451'
        },
        question: 'Who led the parallel government and raised the First Flag of India during the Quit India movement in 1942?',
        options: [
            {
                id: 'pk101',
                value: 'Chittu Pandey'
            },
            {
                id: 'ld542',
                value: 'Mahatma Gandhi'
            },
            {
                id: 're432',
                value: 'Subhas Chandra Bose'
            },
            {
                id: 'md234',
                value: 'Jawaharlal Nehru'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693327903725'
        },
        explanation: 'During the period of Quit India movement, the transfer of power took place by a British administrator to the local people of Ballia. Chittu Pandey led the parallel government and raised the First Flag of India in 1942.',
        __v: 0,
        subtopic: 'Indian National Movement',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64ee2258cbc989e9c16d0455'
        },
        question: 'Who captured Chittorhgarh after defeating Rana Bhim Singh in 1303?',
        options: [
            {
                id: 'pk101',
                value: 'Mubarak Shah Khilji'
            },
            {
                id: 'ld542',
                value: 'Alauddin Khilji'
            },
            {
                id: 're432',
                value: 'Qutb-ud-din Khilji'
            },
            {
                id: 'md234',
                value: 'Nasir-ud-din Khilji'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1693327960734'
        },
        explanation: 'Alauddin Khilji captured Chittorhgarh after defeating Rana Bhim Singh on 26 August 1303. The battle was fought due to queen Padmini, who sacrificed herself in fire (Jouhar Pratha) before the capture.',
        __v: 0,
        subtopic: 'The Delhi Sultanate',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64ee2262cbc989e9c16d0459'
        },
        question: 'What event is celebrated on August 26 as recognized by the U.S. Congress as it granted the right to vote to women in 1920?',
        options: [
            {
                id: 'pk101',
                value: "International Women's Day"
            },
            {
                id: 'ld542',
                value: "Women's Equality Day"
            },
            {
                id: 're432',
                value: "Women's Rights March"
            },
            {
                id: 'md234',
                value: 'Feminist Awareness Day'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1693327970307'
        },
        explanation: "The 19th Amendment to the U.S. Constitution was passed on 26th August, 1920 which granted the women the right to vote. In 1971, the U.S. Congress officially recognized August 26 as Women's Equality Day.",
        __v: 0,
        subtopic: 'Election and Democracy',
        topic: 'Polity'
    },
    {
        _id: {
            $oid: '64ee227acbc989e9c16d045d'
        },
        question: 'Who founded International Dog Day to honour all dogs and encourage adoption?',
        options: [
            {
                id: 'pk101',
                value: 'Jack Hanna'
            },
            {
                id: 'ld542',
                value: 'Jane Goodall'
            },
            {
                id: 're432',
                value: 'Collen Paige'
            },
            {
                id: 'md234',
                value: 'Cesar Millan'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693327994022'
        },
        explanation: 'International Dog Day is celebrated annually on August 26 to honour all dogs and encourage adoption. It was founded in 2004 by Collen Paige, an animal welfare advocate and pet lifestyle expert.',
        __v: 0,
        subtopic: 'Days and Events',
        topic: 'GK'
    },
    {
        _id: {
            $oid: '64ee228bcbc989e9c16d0461'
        },
        question: 'On which day is National Sports Day celebrated annually?',
        options: [
            {
                id: 'pk101',
                value: '26th January'
            },
            {
                id: 'ld542',
                value: '15th August'
            },
            {
                id: 're432',
                value: '2nd October'
            },
            {
                id: 'md234',
                value: '29th August'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693328011694'
        },
        explanation: 'National Sports Day is celebrated on 29th August every year. Major Dhyan Chand was born in Uttar Pradesh in 1905. National Sports Day was declared in 1995.',
        __v: 0,
        subtopic: 'Days and Events',
        topic: 'GK'
    },
    {
        _id: {
            $oid: '64ee22c5cbc989e9c16d0465'
        },
        question: 'In which year was the Khelo India Programme initiated?',
        options: [
            {
                id: 'pk101',
                value: '2005'
            },
            {
                id: 'ld542',
                value: '2010'
            },
            {
                id: 're432',
                value: '2020'
            },
            {
                id: 'md234',
                value: '2017'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693328069998'
        },
        explanation: 'Khelo India Programme was started in 2017 to revive sports culture in India. Khelo India Youth Games and University Games were started to build a strong framework for all sports in India.',
        __v: 0,
        postedAt: {
            $numberLong: '1693478004210'
        },
        subtopic: 'Sports Initiatives',
        topic: 'Sports'
    },
    {
        _id: {
            $oid: '64ee22cdcbc989e9c16d0469'
        },
        question: 'What were the first human cells successfully cloned in 1953?',
        options: [
            {
                id: 'pk101',
                value: 'HeLa cells'
            },
            {
                id: 'ld542',
                value: 'Neuron cells'
            },
            {
                id: 're432',
                value: 'Red blood cells'
            },
            {
                id: 'md234',
                value: 'Skin cells'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693328077085'
        },
        explanation: 'HeLa cells were the first human cells to be successfully cloned in 1953. It tests the effect of radiation, cosmetics, gene mapping and studying human diseases. Cell culture is the process by which cells are grown in a Petri dish.',
        __v: 0,
        postedAt: {
            $numberLong: '1693396976209'
        },
        subtopic: 'Biology',
        topic: 'Science'
    },
    {
        _id: {
            $oid: '64f1aa89b4bb4ca6da9dc3d1'
        },
        question: 'Which independent tribe occupied the Abor Hills during the British Raj?',
        options: [
            {
                id: 'pk101',
                value: 'Khasi'
            },
            {
                id: 'ld542',
                value: 'Naga'
            },
            {
                id: 're432',
                value: 'Abors'
            },
            {
                id: 'md234',
                value: 'Garo'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693559433492'
        },
        explanation: "Abor Hills were occupied by an independent tribe Adi (Abors) during the British Raj. It is located in Arunachal Pradesh. The term Abor is an Assamese word, signifying 'living free' or 'independent'.",
        __v: 0,
        subtopic: 'British Rule in India',
        topic: 'History'
    },
    {
        _id: {
            $oid: '64f1aa8db4bb4ca6da9dc3d5'
        },
        question: 'Which two ports make up Chabahar Port?',
        options: [
            {
                id: 'pk101',
                value: 'Gwadar and Karachi'
            },
            {
                id: 'ld542',
                value: 'Shahid Kalantari and Shahid Beheshti'
            },
            {
                id: 're432',
                value: 'Muscat and Sohar'
            },
            {
                id: 'md234',
                value: 'Bandar Abbas and Bushehr'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1693559437838'
        },
        explanation: 'Chabahar Port is India’s first foreign port project. It has two ports, Shahid Kalantari and Shahid Beheshti. It is considered as a gateway that would enable India to bypass Pakistan and access Afghanistan, and ultimately Central Asia.',
        __v: 0,
        subtopic: 'Port and Seas',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aa92b4bb4ca6da9dc3d9'
        },
        question: 'In which country is Yasuni National Park located?',
        options: [
            {
                id: 'pk101',
                value: 'Colombia'
            },
            {
                id: 'ld542',
                value: 'Peru'
            },
            {
                id: 're432',
                value: 'Brazil'
            },
            {
                id: 'md234',
                value: 'Ecuador'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693559442629'
        },
        explanation: 'Yasuni National Park is located in Ecuador. It is a protected area under the Amazon rainforest. The park is a convergence point for three unique regions, the Equator, Andes Mountains, and the Amazon rainforest.',
        __v: 0,
        subtopic: 'National Parks and Wildlife Sanctuaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aa99b4bb4ca6da9dc3dd'
        },
        question: 'In which year was Kaziranga National Park declared a Tiger Reserve?',
        options: [
            {
                id: 'pk101',
                value: '2010'
            },
            {
                id: 'ld542',
                value: '1908'
            },
            {
                id: 're432',
                value: '1990'
            },
            {
                id: 'md234',
                value: '2006'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693559449340'
        },
        explanation: 'Kaziranga National Park was formed in 1908. The park is located in the Golaghat and Nagaon districts of Assam, India. Kaziranga National Park was declared a Tiger Reserve in the year 2006.',
        __v: 0,
        subtopic: 'National Parks and Wildlife Sanctuaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aa9eb4bb4ca6da9dc3e1'
        },
        question: 'Whose visit to Kaziranga played a pivotal role in initiating protection measures for the Indian one-horned rhinoceros?',
        options: [
            {
                id: 'pk101',
                value: 'Mahatma Gandhi'
            },
            {
                id: 'ld542',
                value: 'Sir John A. Macdonald'
            },
            {
                id: 're432',
                value: 'Queen Victoria'
            },
            {
                id: 'md234',
                value: 'Lady Mary Curzon'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693559454921'
        },
        explanation: "Lady Mary Curzon's visit to Kaziranga and her concern for the Indian one-horned rhinoceros played a pivotal role in initiating protection measures, leading to the eventual creation of Kaziranga National Park.",
        __v: 0,
        subtopic: 'National Parks and Wildlife Sanctuaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aaa7b4bb4ca6da9dc3e5'
        },
        question: 'In which district of Odisha is Simlipal Tiger Reserve (Biosphere Reserve since 2009) located? ',
        options: [
            {
                id: 'pk101',
                value: 'Balasore'
            },
            {
                id: 'ld542',
                value: 'Kendrapara'
            },
            {
                id: 're432',
                value: 'Mayurbhanj'
            },
            {
                id: 'md234',
                value: 'Cuttack'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693559463511'
        },
        explanation: 'Simlipal is a tiger reserve located in the Mayurbhanj district of Odisha. It holds the distinction of being part of the UNESCO World Network of Biosphere Reserves since 2009.',
        __v: 0,
        subtopic: 'National Parks and Wildlife Sanctuaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aaaeb4bb4ca6da9dc3e9'
        },
        question: 'Which two notable falls are found inside Simlipal Tiger Reserve?',
        options: [
            {
                id: 'pk101',
                value: 'Courtallam Falls and Kempty Falls'
            },
            {
                id: 'ld542',
                value: 'Jog Falls and Dudhsagar Falls'
            },
            {
                id: 're432',
                value: 'Athirappilly Falls and Bhimlat Falls'
            },
            {
                id: 'md234',
                value: 'Barehipani Falls and Joranda Falls'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693559470058'
        },
        explanation: 'The Barehipani Falls and the Joranda Falls are notable falls inside Simlipal Tiger Reserve. Khairiburu and Meghasani peaks are present inside the tiger reserve.',
        __v: 0,
        subtopic: 'National Parks and Wildlife Sanctuaries',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aab6b4bb4ca6da9dc3ed'
        },
        question: 'Which is Australia’s largest desert?',
        options: [
            {
                id: 'pk101',
                value: 'Tanami Desert'
            },
            {
                id: 'ld542',
                value: 'Simpson Desert'
            },
            {
                id: 're432',
                value: 'Great Victoria Desert'
            },
            {
                id: 'md234',
                value: 'Strzelecki Desert'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693559478257'
        },
        explanation: 'The Great Victoria desert is Australia’s largest desert. It lies in western Australia and South Australia, extending from the Gibson desert on the north to the Nullarbor plain on the south and eastward from Kalgoorlie-Boulder almost to the Stuart Range.',
        __v: 0,
        subtopic: 'Deserts and Mangroves',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aabcb4bb4ca6da9dc3f1'
        },
        question: 'What are Foehn winds?',
        options: [
            {
                id: 'pk101',
                value: 'Warm, dry downslope winds on the lee side of mountains'
            },
            {
                id: 'ld542',
                value: 'Cold, wet upslope winds on the windward side of mountains'
            },
            {
                id: 're432',
                value: 'Strong winds blowing through valleys'
            },
            {
                id: 'md234',
                value: 'Coastal winds that bring heavy rainfall'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693559484710'
        },
        explanation: 'Foehn winds are warm, dry downslope winds that occur on the lee side of mountains. They usually blow over the Alps in Europe. They are known as Bergwind in South Africa.',
        __v: 0,
        subtopic: 'Earth Atmosphere',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aac0b4bb4ca6da9dc3f5'
        },
        question: 'How does the Foehn wind impact the climate in regions like Switzerland, southern Germany, and Austria?',
        options: [
            {
                id: 'pk101',
                value: 'It results in strong winds'
            },
            {
                id: 'ld542',
                value: 'It brings colder temperatures'
            },
            {
                id: 're432',
                value: 'It causes heavy rainfall'
            },
            {
                id: 'md234',
                value: 'It leads to warmer climates'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693559488120'
        },
        explanation: 'Regions like Switzerland, southern Germany, and Austria have warmer climates due to the warming effect of the Foehn wind. These winds blow over the Alps off the Mediterranean Sea.',
        __v: 0,
        subtopic: 'Earth Atmosphere',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aac7b4bb4ca6da9dc3f9'
        },
        question: 'In which year was the agreement recognizing Katchatheevu as part of Sri Lanka signed?',
        options: [
            {
                id: 'pk101',
                value: '1980'
            },
            {
                id: 'ld542',
                value: '1962'
            },
            {
                id: 're432',
                value: '1974'
            },
            {
                id: 'md234',
                value: '1991'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693559495983'
        },
        explanation: 'In 1974, Prime Ministers Indira Gandhi and Sirima R.D. Bandaranaike of Sri Lanka signed an agreement that recognized Katchatheevu as part of Sri Lanka. It is located in Palk Strait.',
        __v: 0,
        subtopic: 'Treaties and Conventions',
        topic: 'International'
    },
    {
        _id: {
            $oid: '64f1aad0b4bb4ca6da9dc3fd'
        },
        question: 'What is the Palk Strait named after?',
        options: [
            {
                id: 'pk101',
                value: 'Robert Palk'
            },
            {
                id: 'ld542',
                value: 'James Palk'
            },
            {
                id: 're432',
                value: 'William Palk'
            },
            {
                id: 'md234',
                value: 'John Palk Davis'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693559504171'
        },
        explanation: 'The Palk Strait is a narrow strip of water that separates India and Sri Lanka. The Vaigai river of Tamil Nadu flows into it. The Strait is named after Robert Palk, governor of Madras during the Company Raj period.',
        __v: 0,
        subtopic: 'Water Resources',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aad9b4bb4ca6da9dc401'
        },
        question: 'When was Project Elephant launched?',
        options: [
            {
                id: 'pk101',
                value: '2000'
            },
            {
                id: 'ld542',
                value: '1992'
            },
            {
                id: 're432',
                value: '2010'
            },
            {
                id: 'md234',
                value: '2023'
            }
        ],
        answerId: 'ld542',
        createdAt: {
            $numberLong: '1693559513181'
        },
        explanation: 'The Project Elephant was launched in 1992 as a Centrally Sponsored scheme. It was merged with Project Tiger as Project Tiger and Elephant Division in 2023.',
        __v: 0,
        subtopic: 'Wildlife Conservation',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aadeb4bb4ca6da9dc405'
        },
        question: 'Where is Tuvalu, formerly known as the Ellice Islands, located?',
        options: [
            {
                id: 'pk101',
                value: 'In the Indian Ocean'
            },
            {
                id: 'ld542',
                value: 'In the Atlantic Ocean'
            },
            {
                id: 're432',
                value: 'In the Pacific Ocean'
            },
            {
                id: 'md234',
                value: 'In the Arctic Ocean'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693559518200'
        },
        explanation: 'Tuvalu, formerly known as the Ellice Islands, is an island country situated in the Polynesian subregion of Oceania in the Pacific Ocean. The islands are positioned midway between Hawaii and Australia.',
        __v: 0,
        subtopic: 'Islands and Straits',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aae3b4bb4ca6da9dc409'
        },
        question: 'Where was the Kollur Mine, known for producing Golconda diamonds, located?',
        options: [
            {
                id: 'pk101',
                value: 'Tamil Nadu'
            },
            {
                id: 'ld542',
                value: 'Karnataka'
            },
            {
                id: 're432',
                value: 'Telangana'
            },
            {
                id: 'md234',
                value: 'Andhra Pradesh'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693559523309'
        },
        explanation: 'Kollur Mine, situated on the south bank of the Krishna River in Andhra Pradesh, was a significant diamond mine in India. It produced renowned Golconda diamonds, some of which were part of crown jewels.',
        __v: 0,
        subtopic: 'Minerals',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aae9b4bb4ca6da9dc40d'
        },
        question: 'Which seas make up the four seas of the Arctic Ocean referred to in the Northern Sea Route (NSR)?',
        options: [
            {
                id: 'pk101',
                value: 'Hudson Bay, Baffin Bay, Davis Strait, and Greenland Sea'
            },
            {
                id: 'ld542',
                value: 'North Sea, Baltic Sea, Mediterranean Sea, and Black Sea'
            },
            {
                id: 're432',
                value: 'Caspian Sea, Red Sea, Arabian Sea, and Persian Gulf'
            },
            {
                id: 'md234',
                value: 'Kara, Laptev, East Siberian, and Chukchi Sea'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693559529775'
        },
        explanation: 'NSR is the shortest shipping route between Europe and countries of the Asia-Pacific region around the four seas of the Arctic Ocean. These seas are Kara, Laptev, East Siberian and Chukchi Sea.',
        __v: 0,
        subtopic: 'Port and Seas',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aaf2b4bb4ca6da9dc411'
        },
        question: 'Which Himalayan range is home to the Siachen Glacier?',
        options: [
            {
                id: 'pk101',
                value: 'Pir Panjal Range'
            },
            {
                id: 'ld542',
                value: 'Dhauladhar Range'
            },
            {
                id: 're432',
                value: 'Eastern Karakoram Range'
            },
            {
                id: 'md234',
                value: 'Shivalik Range'
            }
        ],
        answerId: 're432',
        createdAt: {
            $numberLong: '1693559538433'
        },
        explanation: 'Siachen Glacier is situated to the North of Nubra valley, east of Gilgit and west of Aksai Chin. The Siachen Glacier is located in the Eastern Karakoram range in the Himalayas, just northeast of Point NJ9842 where the Line of Control between India and Pakistan ends.',
        __v: 0,
        subtopic: 'Climate Change',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aaf7b4bb4ca6da9dc415'
        },
        question: 'Why are cloudy nights warmer compared to clear cloudless nights?',
        options: [
            {
                id: 'pk101',
                value: "Clouds reflect back Earth's heat"
            },
            {
                id: 'ld542',
                value: 'Clouds trap heat from the Sun'
            },
            {
                id: 're432',
                value: 'Clouds absorb heat from the atmosphere'
            },
            {
                id: 'md234',
                value: 'Clouds emit their own heat'
            }
        ],
        answerId: 'pk101',
        createdAt: {
            $numberLong: '1693559543799'
        },
        explanation: 'Cloudy nights are warmer as compared to clear cloudless nights because clouds reflect back the heat given off by Earth. This phenomenon is called terrestrial radiation.',
        __v: 0,
        subtopic: 'Earth Atmosphere',
        topic: 'Geography'
    },
    {
        _id: {
            $oid: '64f1aafeb4bb4ca6da9dc419'
        },
        question: 'Which two countries share Lake Kariba?',
        options: [
            {
                id: 'pk101',
                value: 'South Africa and Mozambique'
            },
            {
                id: 'ld542',
                value: 'Tanzania and Kenya'
            },
            {
                id: 're432',
                value: 'Uganda and Rwanda'
            },
            {
                id: 'md234',
                value: 'Zambia and Zimbabwe'
            }
        ],
        answerId: 'md234',
        createdAt: {
            $numberLong: '1693559550999'
        },
        explanation: "Lake Kariba is located on the border between Zambia and Zimbabwe. It is the world's largest man-made lake by volume.",
        __v: 0,
        subtopic: 'Lakes and Glaciers',
        topic: 'Geography'
    }
]
