import React, { useEffect } from 'react'
import './Home.css'
import Header from '../Header/Header'
import leaderboardIcon from '../../assets/leaderboard2.png'
import quizIcon from '../../assets/quiz2.png'
import quizIcon2 from '../../assets/quiz4.png'
import userIcon from '../../assets/user2.png'
import pdfIcon from '../../assets/pdficon.png'
import rewardsIcon from '../../assets/rewards2.png'
import { useNavigate } from 'react-router-dom'

function Home() {

    const navigate = useNavigate()
    const routeHandler = (route) => {
        navigate('/' + route)
    }

    // useEffect(() => {
    //     if (localStorage.getItem('isAuth') !== 'userLoggedIn') {
    //         navigate('/login')
    //     }
    // })
    return (
        <>
            <div class="grid-container">
                <div class="grid-item cp" onClick={() => routeHandler('')}><img src={quizIcon} alt="" /><div className='feature__text'>Random Quiz</div></div>
                {/* <div class="grid-item cp" onClick={() => routeHandler('daily-quiz')}><img src={quizIcon2} alt="" /><div className='feature__text'>Daily Quiz</div></div> */}
                <div class="grid-item cp" onClick={() => routeHandler('leaderboard')}><img src={leaderboardIcon} alt="" /> <div className='feature__text'>Leaderboard</div></div>
                <div class="grid-item cp" onClick={() => routeHandler('rewards')}><img src={rewardsIcon} alt="" /> <div className='feature__text'>Rewards</div></div>
                <div class="grid-item cp" onClick={() => routeHandler('pdfs')}><img src={pdfIcon} alt="" /><div className='feature__text'>Monthly PDFs</div></div>
                <div class="grid-item cp" onClick={() => routeHandler('profile')}><img src={userIcon} alt="" /><div className='feature__text'>Profile</div></div>
            </div>
        </>
    )
}

export default Home