import React, { useEffect } from 'react'
import './Rewards.css'
import Header from '../Header/Header'
import { useNavigate } from 'react-router-dom'

function Rewards() {
    const navigate = useNavigate()
    useEffect(() => {
        if (localStorage.getItem('isAuth') !== 'userLoggedIn') {
            navigate('/login')
        }
    })
    return (
        <>
            <div className='rewards__container'>
                <div className='header__text'>Rewards List</div>
                <div className='rewards__list__container'>
                    <div>1. Earn upto 50 coins daily by attempting daily quiz questions.</div>
                    <div>2. Earn one coin for daily checkin.</div>
                </div>
            </div>
        </>
    )
}

export default Rewards