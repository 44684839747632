import React, { useState } from 'react'
import Header from '../Header/Header'
import './DailyQuizSet.css'

const questions = [
    {
        question: 'What is the name of this thing whih I want to name here and this options',
        options: ['Paris', 'Berlin', 'Madrid', 'Rome'],
        answerId: 'fa-3'
    },
    {
        question: 'What is the name of this thing whih I want to name here and this options',
        options: ['Paris', 'Berlin', 'Madrid', 'Rome'],
        answerId: 'fa-3'
    },
    {
        question: 'What is the name of this thing whih I want to name here and this options',
        options: ['Paris', 'Berlin', 'Madrid', 'Rome'],
        answerId: 'fa-3'
    }, {
        question: 'What is the name of this thing whih I want to name here and this options',
        options: ['Paris', 'Berlin', 'Madrid', 'Rome'],
        answerId: 'fa-3'
    }, {
        question: 'What is the name of this thing whih I want to name here and this options',
        options: ['Paris', 'Berlin', 'Madrid', 'Rome'],
        answerId: 'fa-3'
    }
]

function DailyQuizSet() {

    const [selectedAnswers, setSelectedAnswers] = useState(Array(questions.length).fill(null));

    const handleAnswerSelect = (questionIndex, answer) => {
        setSelectedAnswers(prevAnswers => {
            const newAnswers = [...prevAnswers];
            newAnswers[questionIndex] = answer;
            return newAnswers;
        });
        console.log('Selected Answers : ', selectedAnswers)
    };

    return (
        <>
            <div className='daily__quiz__set__container'>
                {
                    questions.map((question, index) => (
                        <div key={index}>
                            <p>{question.question}</p>
                            {question.options.map((option, optionIndex) => (
                                <label key={optionIndex}>
                                    <input
                                        type="radio"
                                        name={`question_${index}`}
                                        value={option.value}
                                        checked={selectedAnswers[index] === option}
                                        onChange={() => handleAnswerSelect(index, option)}
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                    ))
                }
                {
                    questions.map((question) => {
                        return (
                            <div className='quit__set__container'>
                                <div className='question__text'>{question.question}</div>
                                <div className='options__container'>
                                    {question.options.map((option) => {
                                        return (
                                            <div className='option__text'>{option.value}</div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })
                }
                <div>
                    <button>Submit</button>
                </div>
            </div>
        </>

    )
}

export default DailyQuizSet