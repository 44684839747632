import React, { useState, useEffect } from 'react'
import io from 'socket.io-client'
import { showTimeInAgoFormat } from '../../utils/Time'
const localSocketURL = 'http://localhost:4055'
const productionSocketURL = 'https://socket.studencity.in'
const socket = io.connect(productionSocketURL)

function QuizRoom() {
    const [username, setUsername] = useState('')
    const [uid, setUid] = useState('')
    const [userRank, setUserRank] = useState(null)
    const [activeUsers, setActiveUsers] = useState([])
    const [roomJoined, setRoomJoined] = useState(false)
    const [quizData, setQuizData] = useState(null)
    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [isCorrect, setIsCorrect] = useState(false)
    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState('')
    const [usernameError, setUsernameError] = useState(false)

    const joinRoom = () => {
        if (username.trim() === '') {
            setUsernameError(true)
            return
        }
        const eventData = {
            type: 'join_room',
            data: {
                uid,
                user: username
            }
        }
        socket.emit('room_event', eventData)
        setRoomJoined(true)
    }

    const handleLeave = () => {
        const eventData = {
            type: 'leave_room',
            data: {
                uid,
                user: username
            }
        }
        socket.emit('room_event', eventData)
        setRoomJoined(false)
    }

    const incrementScore = () => {
        const eventData = {
            type: 'increment_score',
            data: {
                uid
            }
        }
        socket.emit('room_event', eventData)
    }

    const decrementScore = () => {
        const eventData = {
            type: 'decrement_score',
            data: {
                uid
            }
        }
        socket.emit('room_event', eventData)
    }

    const handleAnswer = (selectedOption) => {
        console.log('handle answer : ', selectedOption)
        setSelectedAnswer(selectedOption.id)
        const isCorrectAnswer = selectedOption.id === quizData.answerId

        if (isCorrectAnswer) {
            setIsCorrect(true)
            incrementScore()
        } else {
            setIsCorrect(false)
            decrementScore()
        }

        setTimeout(async () => {
            await fetchQuizData()
        }, 200)
    }

    useEffect(() => {
        const storedUid = localStorage.getItem('uid')
        if (storedUid) {
            setUid(storedUid)
        } else {
            const generatedUid = generateRandomUid()
            setUid(generatedUid)
            localStorage.setItem('uid', generatedUid)
        }
    }, [])

    const generateRandomUid = () => {
        return Math.floor(100000 + Math.random() * 900000).toString()
    }

    const handleSendMessage = () => {
        // Check if username is not an empty string
        if (username.trim() === '') {
            // Show error message
            setUsernameError(true)
            return
        }

        const eventData = {
            type: 'chat',
            data: {
                uid,
                user: username,
                message: newMessage
            }
        }
        socket.emit('room_event', eventData)
        setNewMessage('')
    }

    const handleUsernameChange = (e) => {
        setUsername(e.target.value.substring(0, 20))
    }

    useEffect(() => {
        console.log('Socket : ',socket)
        socket.on('chat_data', (data) => {
            setMessages(data)
        })
        socket.on('room_data', (data) => {
            const sortedUsers = data.sort((a, b) => b.score - a.score)
            const usersWithRank = sortedUsers.map((user, index) => ({
                ...user,
                rank: index + 1
            }))
            setActiveUsers(usersWithRank)
        })
    }, [])

    const fetchQuizData = async () => {
        try {
            const response = await fetch('https://api.studencity.in/api/upsc/quiz/random/v2')
            const data = await response.json()
            setSelectedAnswer(null)
            setQuizData(data?.data?.quiz)
        } catch (error) {
            console.error('Error fetching quiz data:', error)
        }
    }

    useEffect(() => {
        fetchQuizData()
    }, [])

    return (
        <>
            <div className='h-[calc(100vh_-_6vh)] bg-gray-800 text-white flex flex-col sm:flex-row'>
                {/* Chat Container Section */}
                <div className='sm:w-1/5 bg-gray-700 text-white px-4 flex flex-col'>
                    <div className='flex-grow flex flex-col justify-between'>
                        <div className='mt-4'>
                            <div className='flex flex-col flex-grow'>
                                {messages.map((message, index) => (
                                    <div className='mb-2'>
                                        {message.type === 'chat' && (
                                            <>
                                                <div>
                                                    <span className='text-gray-200 font-bold'>
                                                        {message.user} {' :'}{' '}
                                                    </span>
                                                    <span className='text-white'>{message.message}</span>
                                                </div>
                                                <div className='text-xs text-gray-400'>{showTimeInAgoFormat(message.time)}</div>
                                            </>
                                        )}
                                        {message.type === 'join_room' && <div className='text-xs text-green-300'>{message.user} joined the room</div>}
                                        {message.type === 'leave_room' && <div className='text-xs text-red-300'>{message.user} left the room</div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* Chat input */}
                    <div className='mt-4 mb-4'>
                        <input type='text' placeholder='Type your message...' value={newMessage} onChange={(e) => setNewMessage(e.target.value)} className='w-full px-3 py-2 bg-gray-600 text-white rounded focus:outline-none focus:border-none' />
                        <button onClick={handleSendMessage} className='mt-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded'>
                            Send
                        </button>
                    </div>
                </div>

                {/* Main Content Section */}
                <div className='sm:w-3/5 my-8 mx-8'>
                    <h1 className='text-4xl font-bold mb-4'>General Knowledge Quiz</h1>

                    {!roomJoined && (
                        <div className='mb-4'>
                            <label className='block mb-2'>Username:</label>
                            <input type='text' value={username} onChange={(e) => setUsername(e.target.value.substring(0, 20))} className='w-full px-3 border-2 py-2 bg-gray-700 text-white rounded' />
                            <button onClick={joinRoom} className='mt-2 px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded'>
                                Join
                            </button>
                        </div>
                    )}

                    {quizData && roomJoined && (
                        <div className='bg-gray-700 p-4 rounded'>
                            <p className='text-white'>{quizData.question}</p>
                            {quizData.options.map((option, index) => (
                                <div key={option.id} className='mt-2'>
                                    <button onClick={() => handleAnswer(option)} className={`w-full px-4 py-2 rounded ${selectedAnswer === option.id ? (isCorrect ? 'bg-green-500' : 'bg-red-500') : option.id === quizData.answerId && selectedAnswer ? 'bg-green-500' : 'bg-blue-500 hover:bg-blue-600'}`} disabled={selectedAnswer !== null}>
                                        {option.value}
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}

                    {roomJoined && (
                        <button onClick={handleLeave} className='mt-4 px-4 py-2 bg-red-500 hover:bg-red-600 rounded'>
                            Leave Room
                        </button>
                    )}
                </div>

                {/* Leaderboard Container Section */}
                <div className='sm:w-1/5 bg-gray-700 text-white px-4'>
                    <h2 className='text-2xl font-bold mb-2 mt-4 text-center'>Leaderboard</h2>
                    <ul className='list-none p-0'>
                        {activeUsers.map((user) => (
                            <li key={user.uid} className={`text-gray-300 flex justify-between items-center border-b border-gray-500 py-2 ${user.id === uid ? 'current-user' : ''}`}>
                                <span className={`capitalize ${user.uid === uid ? 'font-bold' : ''}`}>
                                    {user.user}
                                    <span className='lowercase mx-2 text-xs text-gray-500'>{showTimeInAgoFormat(user.lastActivity)}</span>
                                </span>
                                <span className={`flex items-center ml-4 font-bold ${user.uid === uid ? 'font-bold' : ''}`}>{user.score}</span>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Error Modal */}
                {usernameError && (
                    <div className='fixed z-10 inset-0 overflow-y-auto'>
                        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                            <div className='fixed inset-0 transition-opacity'>
                                <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
                            </div>
                            <span className='hidden sm:inline-block sm:align-middle sm:h-screen'></span>
                            &#8203;
                            <div className='inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full' role='dialog' aria-modal='true' aria-labelledby='modal-headline'>
                                <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                                    <div className=''>
                                        <div className='mt-3 text-center sm:mt-0 sm:text-left'>
                                            <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-headline'>
                                                Empty Username
                                            </h3>
                                            <div className='mt-2'>
                                                <p className='text-sm text-gray-500'>Please enter a username before sending a message.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                                    <button type='button' onClick={() => setUsernameError(false)} className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 sm:ml-3 sm:w-auto sm:text-sm'>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default QuizRoom
